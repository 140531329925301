import ExhibitorTeamPaperworkAccordion from './accordions/ExhibitorTeamPaperworkAccordion'
import { IPaperworkTeam } from '../../../store/paperworks/paperworkSlice'
import customImageComponent from '../../../components/common/CustomImageComponent'
import { useEffect, useState } from 'react'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { signedStatuses } from '../../../const/data/v01/rider-team-members/firestore-v01-rider-team-member-const'

const openNextSibbling = (e: any) => {
  e.target?.closest('.accordion')?.nextElementSibling?.classList.toggle('activeTab')
}

export const AccordionWrapper = ({
  handleToggle,
  openedMenu,
  accordionIndex,
  userData,
  eventId,
  cbSingSuccess,
  registrationByDay,
}: {
  handleToggle: (value: number) => void
  openedMenu: number[]
  accordionIndex: number
  userData: IPaperworkTeam | null
  cbSingSuccess: () => void
  eventId: string | null
  registrationByDay: IRegistrationByDayInterface
}) => {
  const [signMissing, setSignMissing] = useState(0)

  useEffect(() => {
    if (userData && userData.signedStatus && userData.documents) {
      const notSigned = userData.documents.filter(
        (document) => document.status === signedStatuses.NOT_SIGNED
      )
      setSignMissing(notSigned.length)
    }
  }, [userData])

  return (
    <>
      <div className="mt-3 border-[#D3DAEE] border px-4 py-2 pl-2 rounded-lg transition-all">
        <div
          onClick={(e: any) => {
            openNextSibbling(e)
            handleToggle(accordionIndex)
          }}
          className="text-SeabiscuitDark200ThemeColor flex flex-col lg:flex-row lg:justify-between lg:items-center font-semibold accordion cursor-pointer"
        >
          <div className="flex items-center">
            <span className="w-10 h-10 rounded-md overflow-hidden mr-4">
              {customImageComponent(
                userData?.teamMemberProfilePicture,
                userData?.teamMemberName,
                'w-full h-full object-cover'
              )}
            </span>
            <div className="flex flex-col">
              <span className="text-md text-SeabiscuitDark200ThemeColor font-semibold capitalize">
                {userData?.teamMemberName ?? 'N/A'}{' '}
                <div className="font-normal text-[12px] text-[#3C4B71B2]">
                  {userData?.teamMemberRole ?? 'N/A'}
                </div>
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-3 lg:mt-0 items-center">
            <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
              {userData?.documents && userData?.documents?.length > 0
                ? signMissing === 0
                  ? 'All paperwork signed'
                  : `${signMissing} ${signMissing === 1 ? 'signature' : 'signatures'} missing`
                : ''}
            </div>

            {userData?.documents && userData?.documents?.length > 0 ? (
              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4 add_icon cursor-pointer">
                {openedMenu.includes(accordionIndex) ? (
                  <div className={'add_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                  </div>
                ) : (
                  <div className={'remove_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                  </div>
                )}
              </div>
            ) : (
              'No paperwork'
            )}
          </div>
        </div>

        <div className="faqs-content ">
          {userData && userData.documents && userData.documents?.length > 0 && (
            <div className="faqs-content-inside mt-4">
              <ExhibitorTeamPaperworkAccordion
                docsList={userData.documents}
                title="Document"
                tooltip="Paperwork"
                eventId={eventId}
                cbSingSuccess={() => {
                  cbSingSuccess()
                  setSignMissing((v) => v - 1)
                }}
                registrationByDay={registrationByDay}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
