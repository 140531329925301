// Models
import { selectObjToString } from '../interface.helper'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IHorseCompetitorDocument } from './horse-competiton-paperwork-interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class HorseCompetitonPaperworkModel extends ModelBaseModel<IHorseCompetitorDocument> {
  public id: IHorseCompetitorDocument['id']
  public horseId: IHorseCompetitorDocument['horseId']
  public horseOwnerId: IHorseCompetitorDocument['horseOwnerId']
  public horseMeasurementCards: IHorseCompetitorDocument['horseMeasurementCards']
  public horseCogginsInspectionDate: IHorseCompetitorDocument['horseCogginsInspectionDate']
  public horseCogginsInspectionCertificate: IHorseCompetitorDocument['horseCogginsInspectionCertificate']
  public horseHealthRecordCertificatesImages: IHorseCompetitorDocument['horseHealthRecordCertificatesImages']
  public horseHealthStatus: IHorseCompetitorDocument['horseHealthStatus']
  public horseVaccinationRecordsImages: IHorseCompetitorDocument['horseVaccinationRecordsImages']
  public horseCurrentVaccinationStatus: IHorseCompetitorDocument['horseCurrentVaccinationStatus']
  public horseRhinopneumonitisCurrentStatus: IHorseCompetitorDocument['horseRhinopneumonitisCurrentStatus']
  public horseInfluenzaStatus: IHorseCompetitorDocument['horseInfluenzaStatus']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IHorseCompetitorDocument) {
    super()
    this.id = obj?.id ?? null
    this.horseId = obj?.horseId ?? null
    this.horseOwnerId = obj?.horseOwnerId

    this.horseCogginsInspectionDate =
      this.utcTimestamp({
        key: 'horseCogginsInspectionDate' as any,
        isTimestamp: true,
        value: obj?.horseCogginsInspectionDate,
      }) || new Date()
    this.horseCogginsInspectionCertificate = obj?.horseCogginsInspectionCertificate || []

    this.horseMeasurementCards =
      obj?.horseMeasurementCards?.map((data) => {
        return {
          horseShodStatus: data.horseShodStatus ?? null,
          horseMeasurementDate:
            this.utcTimestamp({
              key: 'horseMeasurementDate' as any,
              isTimestamp: true,
              value: data.horseMeasurementDate,
            }) ?? null,
          horseHeightMeasurement: data.horseHeightMeasurement ?? null,
          horseMeasurementCardType: data.horseMeasurementCardType ?? null,
          horseLeftHeelMeasurement: data.horseLeftHeelMeasurement ?? null,
          horseRightHeelMeasurement: data.horseRightHeelMeasurement ?? null,
          horseMeasurementCardsImage: data.horseMeasurementCardsImage ?? [],
        }
      }) ?? []

    this.horseHealthRecordCertificatesImages = obj?.horseHealthRecordCertificatesImages || []
    this.horseHealthStatus = selectObjToString(obj?.horseHealthStatus) ?? null
    this.horseVaccinationRecordsImages = obj?.horseVaccinationRecordsImages || []
    this.horseCurrentVaccinationStatus =
      selectObjToString(obj?.horseCurrentVaccinationStatus) ?? null
    this.horseRhinopneumonitisCurrentStatus =
      selectObjToString(obj?.horseRhinopneumonitisCurrentStatus) ?? null
    this.horseInfluenzaStatus = selectObjToString(obj?.horseInfluenzaStatus) ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new HorseCompetitonPaperworkModel({
      ...doc.data(),
      id: doc?.id,
    })
  }
}
