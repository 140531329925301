import React, { FC, useContext } from 'react'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Influenza } from './components/Influenza'
import { Rhinopneumonitis } from './components/Rhinopneumonitis'
import { VaccinationRecords } from './components/VaccinationRecords'
import { Health } from './components/Health'
import { Coggins } from './components/Coggins'
import { MeasurementCard } from './components/MeasurementCard'

import { setSelectedHorseCompetetionPaperwork } from '../../store/horses/horseSlice'
import { useAppDispatch } from '../../store/hooks'

import FirebaseStorageService from '../../services/storageService'

import { getConvertedData } from '../../models/interface.helper'
import { IHorseCompetitorDocument } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../models/horse/horse.interface'

import useToasterHelper from '../../helpers/ToasterHelper'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'

import { IProfileReqField } from '../competitor/clinicAndOther/Tabs/components/ProfileFields'

import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'

type IOnRemoveImageButtonClickFn = (args: IOnRemoveImageButtonClickProps) => Promise<void>

export type IOnRemoveImageButtonClickProps = {
  index: number
  rowIndex?: number
  type:
    | 'horseMeasurementCardsImage'
    | 'horseVaccinationRecordsImages'
    | 'horseHealthRecordCertificatesImages'
    | 'horseCogginsInspectionCertificate'
}

const getImage = (image: string) => {
  return image.includes('&format=pdf')
    ? { type: 'file', url: '/assets/img/pdf.png' }
    : { type: 'image', url: image }
}

interface HorsePaperworkProps {
  control: Control<IHorseCompetitorDocument>
  saved: any
  watch: UseFormWatch<IHorseCompetitorDocument>
  onSubmit: (data: any, keepUnsaved: boolean) => Promise<void>
  setValue: UseFormSetValue<IHorseCompetitorDocument>
  selectedHorse: IHorseData | null
  imagesUrlsToDeleteFromStorage: any
  profileFiled?: IProfileReqField
}
export const HorsePaperwork: FC<HorsePaperworkProps> = ({
  control,
  saved,
  watch,
  onSubmit,
  setValue,
  selectedHorse,
  imagesUrlsToDeleteFromStorage,
  profileFiled,
}) => {
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const handleModal = useContext(HandleModalContext)?.handleModal

  const handleManageToastConfirm = (
    index: number,
    type: IOnRemoveImageButtonClickProps['type'],
    rowIndex?: number
  ) => {
    handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: async function () {
        await onRemoveImageButtonClick({
          rowIndex,
          type,
          index,
        })
      },
    })
  }

  const imageSetter = async (key: any, imageUrls: string[]) => {
    setValue(key, [...(watch(key) ?? []), ...imageUrls])

    await onSubmit(watch(), true)
  }

  const onRemoveImageButtonClick: IOnRemoveImageButtonClickFn = async (args) => {
    let imageUrl: string | null = null
    let horsesPaperwork = { ...watch() }
    const { type, index, rowIndex } = args

    try {
      switch (type) {
        case 'horseHealthRecordCertificatesImages':
          imageUrl = watch('horseHealthRecordCertificatesImages')?.[index]!
          horsesPaperwork.horseHealthRecordCertificatesImages?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'horseMeasurementCardsImage':
          if (typeof rowIndex === 'number') {
            imageUrl = watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`)[index]
            horsesPaperwork.horseMeasurementCards?.[rowIndex].horseMeasurementCardsImage?.splice?.(
              index,
              1
            )
            await FirebaseStorageService.deleteFile(imageUrl)
          }
          break

        case 'horseVaccinationRecordsImages':
          imageUrl = watch(`horseVaccinationRecordsImages`)?.[index]!
          horsesPaperwork.horseVaccinationRecordsImages?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'horseCogginsInspectionCertificate':
          imageUrl = watch(`horseCogginsInspectionCertificate`)?.[index]!
          horsesPaperwork.horseCogginsInspectionCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break
      }

      await onSubmit(horsesPaperwork, true)

      dispatch(
        setSelectedHorseCompetetionPaperwork({
          competitionPaperwork: getConvertedData(horsesPaperwork),
        })
      )
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  if (profileFiled?.value === 'horseInfluenza') {
    return <Influenza control={control} saved={saved} />
  }

  if (profileFiled?.value === 'horseRhinopneumonitis') {
    return <Rhinopneumonitis control={control} saved={saved} />
  }

  if (profileFiled?.value === 'horseHealthCertificate') {
    return (
      <Health
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        imageSetter={imageSetter}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
        profileFiled={profileFiled}
      />
    )
  }

  if (profileFiled?.value === 'horseCoggins') {
    return (
      <Coggins
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        imageSetter={imageSetter}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
        profileFiled={profileFiled}
      />
    )
  }

  if (profileFiled?.value === 'horseMeasurementCard') {
    return (
      <MeasurementCard
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
        imagesUrlsToDeleteFromStorage={imagesUrlsToDeleteFromStorage}
        onSubmit={onSubmit}
        setValue={setValue}
        profileFiled={profileFiled}
      />
    )
  }

  return (
    <>
      <MeasurementCard
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
        imagesUrlsToDeleteFromStorage={imagesUrlsToDeleteFromStorage}
        onSubmit={onSubmit}
        setValue={setValue}
      />
      <hr className="w-full my-6" />
      <Coggins
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        imageSetter={imageSetter}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
      />
      <hr className="w-full my-6" />
      <Health
        saved={saved}
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        imageSetter={imageSetter}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
      />
      <VaccinationRecords
        getImage={getImage}
        handleManageToastConfirm={handleManageToastConfirm}
        handleModal={handleModal}
        imageSetter={imageSetter}
        saved={saved}
        selectedHorse={selectedHorse}
        control={control}
        watch={watch}
      />
      <Rhinopneumonitis control={control} saved={saved} />
      <Influenza control={control} saved={saved} />
    </>
  )
}
