import { Modal } from './Modal'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import SearchBar from '../components/SearchBar'
import { CONST } from '../../../const/const'
import { UserModel } from '../../../models/users/user.model'
import { where } from 'firebase/firestore'
import { IUserInterface } from '../../../models/users/user.interface'
import FirestoreService from '../../../services/firestoreService'
import ProfileImage from '../components/ProfileImage'
import { getUserFullName } from '../../../helpers/helpers'
import { useChat } from '../components/DashboardChat'
import { v4 as uuidv4 } from 'uuid'
import { useChatContext } from 'stream-chat-react'
import { ChannelData } from 'stream-chat'
import { useAppSelector } from '../../../store/hooks'
import { selectProfileData } from '../../../store/user/userSlice'

export function UserCard({
  className = '',
  selected,
  name,
  userName,
  userProfilePicture,
}: {
  className?: string
  selected: boolean
  name: string
  userName: string
  userProfilePicture?: string
}) {
  return (
    <div
      className={`flex flex-row w-full items-center justify-between px-4 py-1 caret-transparent ${className}`}
    >
      <div className="flex flex-row items-center gap-2">
        <ProfileImage
          src={userProfilePicture}
          lazyLoad={true}
          className={'h-[40px] w-[40px] object-cover rounded-full'}
        />

        <div className="grid gap-1">
          <div className="font-semibold">{name}</div>
          <div className="text-sm text-muted-foreground text-left">@{userName}</div>
        </div>
      </div>

      <img
        alt="Silhouette of a person's head"
        src={selected ? '/assets/chat/ok.svg' : '/assets/chat/filled_circle.svg'}
        className={`h-[20px] w-[20px] object-cover rounded-full`}
      />
    </div>
  )
}

const fetchUsers = async ({
  limit,
  offset,
  search,
}: {
  limit: number
  offset: string | null
  search?: string
}) => {
  const userSnapshots = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
    search
      ? [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.FIELDS.USER_NAME_NGRAMS.KEY,
            'array-contains',
            search
          ),
        ]
      : [],
    limit,
    null,
    null,
    offset
  )

  const users: IUserInterface[] = []
  let nextOffset: string | null = null
  if (userSnapshots.size) {
    userSnapshots.forEach((curr) => {
      users.push(UserModel.fromFirestoreDoc(curr).toObject())
    })
    nextOffset = userSnapshots.docs[userSnapshots.size - 1].id
  }
  return { users, nextOffset }
}

interface Props {
  open: boolean
  onClose: () => void | Promise<void>
  onSubmit: (users: { id: string; username: string; userFullName: string | null }[]) => void
  title?: string
}

const MembersModal = ({ open, onClose, onSubmit, title }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<
    { id: string; username: string; userFullName: string | null }[]
  >([])
  const [search, setSearch] = useState('')
  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['users', search],
      queryFn: (ctx) => fetchUsers({ limit: 20, offset: ctx.pageParam, search }),
      getNextPageParam: (lastPage) => {
        if (lastPage.users.length < 20) return undefined
        return lastPage.nextOffset
      },
      initialPageParam: null as string | null,
    })

  const toggleUserSelection = (user: {
    id: string
    username: string
    userFullName: string | null
  }) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.some((u) => u.id === user.id)
        ? prevSelectedUsers.filter((u) => u.id !== user.id)
        : [...prevSelectedUsers, user]
    )
  }

  const allRows = data ? data.pages.flatMap((d) => d.users) : []

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 60, []),
    overscan: 10,
  })

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      console.log('fetchNextPage!')
      fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="lg:w-[640px] lg:h-[640px] bg-white transition duration-500 rounded-xl p-5 text-black flex flex-col justify-between">
        <div className="flex flex-col h-full w-full items-center">
          <div className="flex-1 flex-col w-full">
            <h4 className="font-bold text-xl text-[#3C4B71] py-2">
              {title ?? 'Select a member to message'}
            </h4>
            <SearchBar search={search} setSearch={setSearch} />
            {status === 'pending' ? (
              <p>Loading...</p>
            ) : status === 'error' ? (
              <span>Error: {error.message}</span>
            ) : (
              <div ref={parentRef} className="h-[460px] w-full overflow-auto">
                <div
                  style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
                  className={`relative w-full`}
                >
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > allRows.length - 1
                    const user = allRows[virtualRow.index]
                    return (
                      <div
                        key={virtualRow.index}
                        onClick={() =>
                          toggleUserSelection({
                            id: user.id,
                            username: user.userName ?? '',
                            userFullName: getUserFullName(user),
                          })
                        }
                        style={{
                          transform: `translateY(${virtualRow.start}px)`,
                          height: `${virtualRow.size}px`,
                        }}
                        className={`absolute top-0 left-0 w-full `}
                      >
                        {isLoaderRow ? (
                          hasNextPage ? (
                            'Loading more...'
                          ) : (
                            'Nothing more to load'
                          )
                        ) : (
                          <UserCard
                            className="h-full"
                            selected={selectedUsers.some((u) => u.id === user.id)}
                            userProfilePicture={user?.userProfilePicture}
                            name={`${user?.userFirstName} ${user?.userLastName}`}
                            userName={user?.userName ?? ''}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          <div>{isFetching && !isFetchingNextPage ? 'Background Updating...' : null}</div>
          <button
            onClick={() => {
              if (!selectedUsers.length) return
              onSubmit(selectedUsers)
              setSelectedUsers([])
              onClose()
            }}
            className="bg-[#F7074F] h-[56px] w-[243px] rounded-md text-white"
          >
            Next
          </button>
        </div>
        <button className="absolute right-0 top-0 p-[10px] text-[#122B46]" onClick={onClose}>
          X
        </button>
      </div>
    </Modal>
  )
}

export function NewChatModal() {
  const { newChatModal, setNewChatModal, userId, eventId } = useChat()
  const { client, setActiveChannel } = useChatContext()
  const profileData = useAppSelector(selectProfileData)

  return (
    <MembersModal
      open={newChatModal}
      onClose={() => setNewChatModal(false)}
      onSubmit={async (users) => {
        //newChat({ users: [otherUserId, userId], dispatch })
        const otherUserIds = users.map((user) => user.id)
        let channel
        const channelData: ChannelData = eventId ? { eventId } : {}
        try {
          if (users.length === 1) {
            channel = client.channel('messaging', {
              members: [...otherUserIds, userId],
              ...channelData,
            })
          } else if (users.length > 1) {
            const channelName =
              users.map((user) => user.userFullName).join(', ') + `-${getUserFullName(profileData)}`

            const channelId = uuidv4()
            channel = client.channel('messaging', channelId, {
              name: channelName,
              ...channelData,
            })
            await channel.create()
            await channel.addMembers([...otherUserIds, userId])
          }

          await channel?.watch()
          await channel?.show()
          setActiveChannel(channel)
        } catch (error) {
          // TO DO: improve log error
          console.error(`Error creating chat: ${error}`)
        }
      }}
    />
  )
}

export function AddMemberModal() {
  const { memberModalOpen, setMemberModalOpen } = useChat()
  const { channel: activeChannel } = useChatContext()
  return (
    <MembersModal
      open={memberModalOpen}
      title="Add member"
      onClose={() => setMemberModalOpen(false)}
      onSubmit={async (otherUserIds) => {
        try {
          await activeChannel?.addMembers(otherUserIds.map((user) => user.id))
        } catch (error) {
          // TO DO: improve log error
          console.error(`Error adding members to chat: ${error}`)
        }
      }}
    />
  )
}
