import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { where } from 'firebase/firestore'

import { TableBtn } from '../components/TableBtn'
import { TableName } from '../components/TableName'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import useHorse from '../../common/table/HorsesManage/hooks/useHorse'
import { BackNumber } from '../components/BackNumber'

import FirestoreService from '../../../../../services/firestoreService'

import helpers from '../../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, generateName, getUserFullName } from '../../../../../helpers/helpers'

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { organizerSliceAcs } from '../../../../../store/organizer/organizerSlice'
import { selectedEvent } from '../../../../../store/events/eventsSlice'

import { getConvertedData } from '../../../../../models/interface.helper'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { RiderTeamMemberModel } from '../../../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../../../models/rider-team-member/riderTeamMember.interface'
import { RecipientModel } from '../../../../../models/recipients/recipients'

import { IRegisteredUser, IRider } from '../../../../../types/entryRider'

import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { CONST } from '../../../../../const/const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { IconSwitch } from '../../../../../components/icons/IconSwitch'
import { UserDocumentModel } from '../../../../../models/user-documents/user-documents.model'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'

const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

export enum ETabId {
  entries,
  spectators,
  horses,
  checkIn,
}

interface ITab {
  tab: string
  id: ETabId
  disabled?: boolean
}
export const MANAGE_CLINIC_TABS: ITab[] = [
  { tab: 'Entries', id: ETabId.entries },
  { tab: 'Spectators', id: ETabId.spectators, disabled: true },
  { tab: 'Horses', id: ETabId.horses, disabled: true },
  // { tab: 'Check In', id: ETabId.checkIn, disabled: true },
]

export const ALL_CLASSES = { value: 'all', label: 'All Classes' }

enum EStatuses {
  all = 'All',
  good = 'Good',
  bad = 'Bad',
  scratched = 'Scratched',
}
export const ALL_STATUSES = { value: EStatuses.all, label: EStatuses.all }

enum ESorts {
  none,
  rider,
  horse,
  back,
}
export const useManageClinicNOtherAdminTabNew = (eventId: string) => {
  const { horses, loading: horsesLoading } = useHorse(eventId)
  const selectedEvent_ = useAppSelector(selectedEvent)

  // useGetBasicEventDetails(eventId)
  const handleModalContext = useContext(HandleModalContext)
  const [ridersClasses, setRidersClasses] = useState<(typeof ALL_CLASSES)[]>([])
  const [activeTab, setActiveTab] = useState<ETabId>(ETabId.entries)

  // const exhibitors = useAppSelector(organizerReducers.exhibitors)
  const [riders, setRiders] = React.useState<IRider[]>([])
  const [freezedRiders, setFreezedRiders] = React.useState<IRider[]>([])
  const [activeClass, setActiveClass] = React.useState({ value: '', label: '' })
  const [statuses] = React.useState([
    ALL_STATUSES,
    { value: EStatuses.good, label: EStatuses.good },
    { value: EStatuses.bad, label: EStatuses.bad },
    { value: EStatuses.scratched, label: EStatuses.scratched },
  ])
  const [activeStatus, setActiveStatus] = React.useState(ALL_STATUSES)

  const [registeredUsers, setRegisteredUsers] = React.useState<IRegisteredUser[]>([])
  const [users, setUsers] = React.useState<IUserInterface[]>([])
  const [searchNameValue, setSearchNameValue] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const toastFunction = useToasterHelper()
  const [activeSortType, setActiveSortType] = React.useState<ESorts>(ESorts.none)
  const onViewReceiptClick = async (
    recipientId: number | string | null | undefined,
    registrationId: number | string | null | undefined
  ) => {
    if (!recipientId || !registrationId) {
      return toastFunction.info({ message: MESSAGES_CONST.NO_RECIPIENT })
    }

    try {
      const docSnapshot = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
        [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
            '==',
            recipientId
          ),
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ],
        1
      )
      let recipient = RecipientModel.fromFirestoreDoc(docSnapshot.docs[0]).toObject()
      handleModalContext?.handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, {
        bill: recipient,
      })
    } catch (e) {
      console.log('=>(useManageClinecNOtherAdminTabNew.tsx:124) e', e)
    }
  }
  const tableColumns = [
    {
      name: (
        <TableName
          title={'Back #'}
          isActive={activeSortType === ESorts.back}
          titleAdds={
            !searchNameValue.length &&
            activeStatus.value === ALL_STATUSES.value &&
            activeClass.value === ALL_CLASSES.value ? (
              <span
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.AUTO_ASSIGN_MODAL, {
                    riders,
                    cb: (arrValues: number[]) => {
                      const newRiders = [...freezedRiders]
                      arrValues.forEach((num, i) => {
                        if (newRiders[i]) newRiders[i].backNumber = num
                      })
                      setRiders(newRiders)
                      setFreezedRiders(newRiders)
                    },
                  })
                }}
                className={'text-[#122B4680] opacity-50 cursor-pointer hover:opacity-90'}
              >
                • Auto
              </span>
            ) : (
              <></>
            )
          }
          btnHandler={() => sortHandler('backNumber')}
        />
      ),
      cell: (row: IRider) => (
        <BackNumber
          id={row.id || ''}
          backNumber={row.backNumber}
          registeredUsers={registeredUsers}
          riders={freezedRiders}
          cbUpdate={(value) => {
            const newFreezedRiders = [...freezedRiders]
            const newRiders = [...riders]
            const freezedRiderIndex = newFreezedRiders.findIndex((r) => r.id === row.id)
            const riderIndex = newRiders.findIndex((r) => r.id === row.id)
            newFreezedRiders[freezedRiderIndex].backNumber = value
            newRiders[riderIndex].backNumber = value
            setRiders(newRiders)
            setFreezedRiders(newFreezedRiders)
          }}
        />
      ),
      width: '8%',
    },
    {
      name: (
        <TableName
          title={'Rider'}
          isActive={activeSortType === ESorts.rider}
          btnHandler={() => sortHandler('riderName')}
        />
      ),
      cell: (row: IRider) => (
        <>
          <Link
            to={`${CONST.ROUTES.MANAGE.MANAGE_RIDER.URL}/${row?.userId}/${eventId}/${row?.registrationDocId}`}
            className="flex items-center gap-4 w-[300px] hover:opacity-70 transition-all"
          >
            <span className="p-[2px] bg-[#f6f7fb] my-2 flex items-center rounded-full justify-center shrink-0">
              <img
                alt="icons"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${helpers.generateName(row.riderName ?? UNKNOWN)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
                src={row?.riderProfilePicture ?? ''}
                className="w-[45px] h-[45px] object-cover rounded-full"
              />
            </span>
            <div className={'max-w-[100%]'}>
              <span className="text-base block text-SeabiscuitDark200ThemeColor font-normal capitalize max-w-[220px] ellipsis">
                {row.riderName ?? UNKNOWN}
              </span>
              <span className={'block text-[#122b4680]'}>
                Registered by •{' '}
                <span className={'underline cursor-pointer'}>
                  {row.registeredBy || row.riderName || UNKNOWN}
                </span>
              </span>
            </div>
          </Link>
        </>
      ),
      width: '20%',
    },
    {
      name: (
        <TableName
          isActive={activeSortType === ESorts.horse}
          title={'Horse'}
          btnHandler={() => sortHandler('horseName')}
        />
      ),
      cell: (row: IRider) => {
        if (!row.horse) {
          return <>N/A</>
        }
        return (
          <div className={'flex gap-2 items-center'}>
            <span className="p-[2px] bg-[#f6f7fb] my-2 flex items-center rounded-full justify-center shrink-0">
              <img
                src={row.horseProfilePicture ?? ''}
                alt="icons"
                className="object-cover w-[45px] h-[45px] rounded-full"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${generateName(row.horseName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            </span>
            <div>
              <div className={'flex items-center gap-2'}>
                <span>{row.horse.horseName || 'N/A'}</span>
                <button
                  className={'group'}
                  onClick={() => {
                    handleModalContext?.handleModal(true, MODAL_CONSTS.SWITCH_HORSE, {
                      rider: row,
                      cb: (horse: IHorseData) => {
                        const newRiders = [...riders]
                        const newFreezedRiders = [...freezedRiders]
                        const riderIndex = newRiders.findIndex((r) => r.id === row?.id)
                        const riderFreezedIndex = newFreezedRiders.findIndex(
                          (r) => r.id === row?.id
                        )

                        if (!newFreezedRiders[riderFreezedIndex]) return null
                        if (!newRiders[riderIndex]) return null

                        newRiders[riderIndex].horseProfilePicture = horse.horseProfilePicture
                        newFreezedRiders[riderFreezedIndex].horseProfilePicture =
                          horse.horseProfilePicture

                        newRiders[riderIndex].horseName = horse.horseName
                        newFreezedRiders[riderFreezedIndex].horseName = horse.horseName

                        newRiders[riderIndex].horseId = horse.horseId || ''
                        newFreezedRiders[riderFreezedIndex].horseId = horse.horseId || ''

                        newRiders[riderIndex].horse = horse
                        newFreezedRiders[riderFreezedIndex].horse = horse
                        setRiders(newRiders)
                        setFreezedRiders(newFreezedRiders)

                        handleModalContext?.handleModal(false, MODAL_CONSTS.SWITCH_HORSE)
                      },
                    })
                  }}
                >
                  <IconSwitch />
                </button>
              </div>
              <span className={'block text-[#122b4680]'}>
                Trainer •{' '}
                <span className={'underline cursor-pointer'}>
                  {(row.horse as any).trainer || 'N/A'}
                </span>
              </span>
            </div>
          </div>
        )
      },
      width: '20%',
    },
    {
      name: <TableName title={'Classes'} centered />,
      cell: (row: IRider) => (
        <>
          {!row.classes[0] || row.isScratched ? (
            <span className={'block w-[100%] md:text-center text-[#122b4680] text-[14px]'}>
              All Scratched
            </span>
          ) : (
            <span className={'block w-[100%] md:text-center text-[#122b4680] text-[14px]'}>
              {row.scratchedClasses && row.scratchedClasses.length > 0
                ? `${row.classes.length} live, ${row.scratchedClasses.length} scratched`
                : `
                 ${row.classes.length} class${row.classes.length > 1 ? 'es' : ''}
                `}
            </span>
          )}
        </>
      ),
      width: '10%',
    },
    {
      name: <TableName title={'Paperwork'} centered />,
      width: '10%',
      cell: (row: IRider) => {
        const isUnsigned = row.documents?.some((d) => d.status !== 'Signed')

        return (
          <>
            <div className="md:justify-center w-full flex text-[14px]">
              {isUnsigned ? (
                <span className={'text-[#F7074F]'}>Unsigned</span>
              ) : (
                <span className={'text-[#122B4680] opacity-[50%]'}>Signed</span>
              )}
            </div>
          </>
        )
      },
    },
    {
      name: <TableName title={'Payment'} centered />,
      cell: (row: IRider) => {
        const teamMember = riders.find((r) => row.riderTeamMember?.userId === r.riderId)

        return (
          <>
            <div className="md:justify-center w-full flex text-[14px]">
              {row?.paymentStatus === PAYMENT_STATUSES.PAID ||
              teamMember?.paymentStatus === PAYMENT_STATUSES.PAID ? (
                <span className={'text-[#122B4680] opacity-[50%]'}>Paid</span>
              ) : (
                <span
                  onClick={() => {
                    dispatch(organizerSliceAcs.setRegistrationByDayId(row.id))
                    onViewReceiptClick(row.userId, row.registrationDocId).then()
                  }}
                  className={'cursor-pointer text-[#F7074F]'}
                >
                  Unpaid
                </span>
              )}
            </div>
          </>
        )
      },
      width: '10%',
    },
    {
      name: <TableName title={'Answers'} centered />,
      cell: (row: IRider) => {
        return (
          <div
            className={'text-center w-full text-[#122B4680] cursor-pointer hover:text-[#F7074F]'}
          >
            <span
              onClick={() =>
                handleModalContext?.handleModal(true, MODAL_CONSTS.ANSWERS, {
                  rider: row,
                })
              }
            >
              {row.comments?.length || 0} answers
            </span>
          </div>
        )
      },
      width: '10%',
    },
    {
      name: <TableName title={'Manage'} centered />,
      cell: (row: IRider) => (
        <div className={'flex gap-2 md:justify-center w-[100%]'}>
          <TableBtn
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: row.riderEmail,
                user_name: row.riderName,
                profilePicture: row.riderProfilePicture,
              })
            }}
            type={'message'}
          />
          <TableBtn
            onClick={() => {
              if (row.isSratched && !row.classes[0]) {
                return null
              }
              if (row?.paymentStatus !== PAYMENT_STATUSES.PAID) {
                return toastFunction.info({ message: MESSAGES_CONST.BILL_NOT_PAYED })
              }
              if (!row.recipientId) {
                return toastFunction.error({ message: MESSAGES_CONST.NO_RECIPIENT })
              }

              handleModalContext?.handleModal(true, MODAL_CONSTS.SCRATCH_ENTRY, {
                rider: row,
                cb: (success: boolean) => {
                  if (success) {
                    const newRiders = [...freezedRiders]
                    const riderIndex = freezedRiders.findIndex((rider) => rider.id === row.id)

                    if (newRiders[riderIndex]) {
                      newRiders[riderIndex].isSratched = true
                      newRiders[riderIndex].isScratched = true
                      newRiders[riderIndex].classes = []
                    }
                    setRiders(newRiders)
                    setFreezedRiders(newRiders)
                  } else {
                  }
                },
              })
            }}
            type={row.isSratched && !row.classes[0] ? 'scratched' : 'remove'}
          />

          <Link
            to={`${CONST.ROUTES.MANAGE.MANAGE_RIDER.URL}/${row?.userId}/${eventId}/${row?.registrationDocId}`}
            className="hover:opacity-70 transition-all"
          >
            <TableBtn type={'go'} />
          </Link>
        </div>
      ),
      width: '12%',
    },
  ]
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (horsesLoading) return
    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'useEffect',
          fileName: 'ExhibitorsListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        }),
      })

      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      dispatch(organizerSliceAcs.resetExhibitorAc())

      return
    }

    dispatch(
      organizerSliceAcs.setExhibitorsAc({
        eventId,
        cursorId: null,
        tabName: 'all',
      })
    )
    ;(async () => {
      const registeredUsersByDay: IRegistrationByDayInterface[] = []
      const riderTeamMembers: IRiderTeamMemberInterface[] = []
      const userData: IUserInterface[] = []

      const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

      usersSnaps.forEach((currSnap) => {
        userData.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      })

      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', eventId)]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }

      const registeredUsersByDaySnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [
          where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
          where(
            COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.KEY,
            '!=',
            PAYMENT_STATUSES.PENDING
          ),
        ]
      )

      registeredUsersByDaySnaps.docs.forEach((currDoc) => {
        registeredUsersByDay.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      const newRegisteredUsersByDay = registeredUsersByDay.map((ru) => ({
        ...ru,
        riderTeamMember: riderTeamMembers.find(
          (rtm) => rtm.registrationDocId === ru.registrationDocId
        ),
      })) as IRegisteredUser[]

      setRegisteredUsers(newRegisteredUsersByDay)
      setUsers(userData)
      setLoading(false)
    })()

    return () => {
      dispatch(organizerSliceAcs.resetExhibitorAc())
    }

    // eslint-disable-next-line
  }, [eventId, horsesLoading])

  React.useEffect(() => {
    if (selectedEvent_.EventFees && freezedRiders[0]) {
      const allClassesLabel = {
        value: ALL_CLASSES.value,
        label: `${ALL_CLASSES.label}  <span class="text-[#122B4680]">• ${freezedRiders.length}  entries</span>`,
      }
      const arrClasses =
        selectedEvent_.EventFees?.registrationFees?.map((itm) => ({
          value: itm.name || '',
          label: `${itm.name} <span class="text-[#122B4680]">• ${freezedRiders.filter((r) => r.classes.includes(itm.name || '')).length} entries</span>`,
        })) || []
      const newRidersClasses: typeof ridersClasses = [allClassesLabel, ...arrClasses]

      setActiveClass(allClassesLabel)
      setRidersClasses(newRidersClasses)
    }
  }, [selectedEvent_.EventFees, freezedRiders])

  React.useEffect(() => {
    if (registeredUsers[0]) {
      const newRiders: IRider[] = []

      const groupedByHorseAndRider = registeredUsers.reduce((acc: any, item) => {
        const key = `${item.horseId}_${item.riderId}`

        if (!acc[key]) {
          acc[key] = { ...item, classes: [], scratchedClasses: [] }
        }
        if (item.registrationByDayName && !item.isSratched && !item.isScratched) {
          acc[key].classes.push(item.registrationByDayName)
        } else if (item.registrationByDayName && (item.isSratched || item.isScratched)) {
          acc[key].scratchedClasses.push(item.registrationByDayName)
        }

        return acc
      }, {})
      registeredUsers.forEach((rider) => {
        const horse = horses.find((hr) => hr.id === rider.horseId)
        let classes: string[] = []
        let scratchedClasses: string[] = []
        if (groupedByHorseAndRider[`${rider.horseId}_${rider.riderId}`]) {
          classes = [...groupedByHorseAndRider[`${rider.horseId}_${rider.riderId}`].classes]
          scratchedClasses = [
            ...groupedByHorseAndRider[`${rider.horseId}_${rider.riderId}`].scratchedClasses,
          ]
        }
        const hasRider = newRiders.find(
          (rd) =>
            rd.registrationDocId === rider.registrationDocId &&
            rd.riderId === rider.riderId &&
            rd.horseId === rider.horseId
        )

        if (!hasRider) {
          const user = users.find((user) => user.id === rider.userId)

          newRiders.push({
            ...rider,
            horse,
            documents: [],
            registeredBy: user ? getUserFullName(user) : '',
            classes,
            scratchedClasses,
          })
        }
      })
      ;(async () => {
        let documentsSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
          where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
          // where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.COMPITETOR_ID.KEY, '==', exhibitorAllData.userId),
        ])

        let documents = documentsSnaps.docs.map((currDoc) =>
          getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
        )

        newRiders.forEach((rider) => {
          rider['documents'] = documents.filter(
            (d) => d.signatoryId === rider.riderId
          ) as UserDocumentModel[]
        })

        setRiders(newRiders)
        setFreezedRiders(newRiders)
      })()
    }
  }, [registeredUsers, horses])

  const onchangeSearchRiderName = (value: string) => {
    setSearchNameValue(value)
    if (value.length < 1) {
      return setRiders(freezedRiders)
    }
    const newRiders = freezedRiders.filter(
      (rider) =>
        rider.riderName?.toLowerCase().includes(value.toLowerCase()) ||
        rider.horseName?.toLowerCase().includes(value.toLowerCase())
    )

    return setRiders(newRiders)
  }

  const statusSelectHandler = (itm: typeof ALL_STATUSES) => {
    setActiveStatus(itm)
    if (itm.value === ALL_STATUSES.value) {
      return setRiders(freezedRiders)
    }

    if (EStatuses.scratched === itm.value) {
      const newRiders = freezedRiders.filter((rider) => rider.isSratched)
      return setRiders(newRiders)
    }
    if (EStatuses.good === itm.value) {
      const newRiders = freezedRiders.filter(
        (rider) =>
          rider.paymentStatus === PAYMENT_STATUSES.PAID &&
          rider.riderTeamMember.mailLog &&
          rider.riderTeamMember.mailLog.find((m) => m.status === 'Signed') &&
          rider.classes.length
      )
      return setRiders(newRiders)
    }
    if (EStatuses.bad === itm.value) {
      const newRiders = freezedRiders.filter(
        (rider) =>
          rider.paymentStatus !== PAYMENT_STATUSES.PAID ||
          (rider.riderTeamMember.mailLog &&
            rider.riderTeamMember.mailLog.find((m) => m.status !== 'Signed')) ||
          rider.classes.length === 0
      )
      return setRiders(newRiders)
    }
  }
  const selectClassHandler = (itm: typeof ALL_CLASSES) => {
    setActiveClass(itm)
    if (itm.value === ALL_CLASSES.value) {
      return setRiders(freezedRiders)
    }

    const newRiders = freezedRiders.filter((rider) => rider.classes.find((cl) => cl === itm.value))
    setRiders(newRiders)
  }

  function sortHandler(type: 'riderName' | 'backNumber' | 'horseName') {
    if (activeSortType !== ESorts.none) {
      const newRiders = riders.sort((a, b) => {
        const v1 = type === 'backNumber' ? +a[type] || 0 : a[type] || ''
        const v2 = type === 'backNumber' ? +b[type] || 0 : b[type] || ''

        if (v1 < v2) {
          return -1
        }
        if (v1 > v2) {
          return 1
        }
        return 0
      })
      setRiders(newRiders)
      setActiveSortType(ESorts.none)
    } else {
      const newRiders = riders.sort((a, b) => {
        const v1 = type === 'backNumber' ? +a[type] || 0 : a[type] || ''
        const v2 = type === 'backNumber' ? +b[type] || 0 : b[type] || ''

        if (v1 > v2) {
          return -1
        }
        if (v1 < v2) {
          return 1
        }
        return 0
      })
      setRiders(newRiders)
      switch (type) {
        case 'riderName':
          return setActiveSortType(ESorts.rider)
        case 'horseName':
          return setActiveSortType(ESorts.horse)
        default:
          return setActiveSortType(ESorts.back)
      }
    }
  }

  return {
    selectClassHandler,
    tableColumns,
    riders,
    horses,
    activeTab,
    setActiveTab,
    activeClass,
    setActiveClass,
    ridersClasses,
    onchangeSearchRiderName,
    searchNameValue,
    loading,
    selectedEvent: selectedEvent_,
    statusSelectHandler,
    activeStatus,
    statuses,
  }
}
