import { useContext, useEffect } from 'react'

import { IonItem, useIonRouter } from '@ionic/react'

// Constants
import { CONST } from '../../../../const/const'

// Helpers
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { useHistory } from 'react-router-dom'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

type IManageClinicNOtherTabsProps = {
  setEventTab: any
  eventId: any
  eventTab: any
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ManageClinicNOtherTabs = (props: IManageClinicNOtherTabsProps) => {
  // Hooks and vars
  const eventFormIndex = 0
  const { setEventTab, eventId, eventTab } = props
  const router = useIonRouter()
  const handleModalContext = useContext(HandleModalContext)

  useEffect(() => {
    if (router.routeInfo.lastPathname?.includes('manage/clinic/revenue/registration')) {
      setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])
  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openManageEventModal = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.MANAGE_EVENT_MODAL, {
      eventId,
      headingClassName: '!text-SeabiscuitDark200ThemeColor',
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const setTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-4 text-center h-10 relative min-w-fit text-sm transition ease-in duration-200 text-md focus:outline-none rounded-full`

    if (eventTab === value || sequence < event_index) {
      style = `${style} `

      if (eventTab === value) {
        return `${style} bg-SeabiscuitWhiteThemeColor text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitGrayThemeColor`
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleScroll = (e: any) => {
    const page = e.target
    if (0) console.log(page)
  }

  return (
    <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3 md:gap-0">
      <IonItem lines="none" className="bg-red-400 editEventTabs w-full md:w-[initial]">
        <div className="Innrclass bg-SeabiscuitGrayThemeColor w-full">
          <div
            onScroll={handleScroll}
            className="flex overflow-x-auto gap-x-2 tabScroll bg-SeabiscuitGrayThemeColor w-full"
          >
            {/**************************************************************/}
            {/* Admin */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE)
                }
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/NameTag-2.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/NameTag-1.svg" />
                  )}
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Admin
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* Logistic */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.VALUE)
                }
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Bedding-1.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Bedding.svg" />
                  )}
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Logistics
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* Operations */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.VALUE)
                }
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/NameTag-2.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/NameTag-1.svg" />
                  )}
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Operations
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* Staff */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.VALUE)
                }
                // router.push(`${CONST.ROUTES.MANAGE.STAFF.URL}/${eventId}`)
                // history.push(`${CONST.ROUTES.MANAGE.STAFF.URL}/${eventId}`)
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  <img
                    alt="icons"
                    className="mr-2 w-5"
                    src={
                      eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.VALUE
                        ? `/assets/cp_icons/NameTag-2.svg`
                        : `/assets/cp_icons/NameTag-1.svg`
                    }
                  />
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Staff
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* FINANCES */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE)
                }
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE ? (
                    <img
                      alt="icons"
                      className="mr-2 w-5"
                      src="/assets/cp_icons/UsDollarCircled.svg"
                    />
                  ) : (
                    <img
                      alt="icons"
                      className="mr-2 w-5"
                      src="/assets/cp_icons/UsDollarCircled1.svg"
                    />
                  )}
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Finances
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* MESSAGES */}
            {/**************************************************************/}

            {/*<button*/}
            {/*  type="button"*/}
            {/*  onClick={() => {*/}
            {/*    if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.SEQUENCE <= eventFormIndex) {*/}
            {/*      setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.VALUE)*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  className={`min-w-[130px] w-full*/}
            {/*            ${setTabStyle(*/}
            {/*              CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.VALUE,*/}
            {/*              CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.SEQUENCE,*/}
            {/*              eventFormIndex*/}
            {/*            )}`}*/}
            {/*>*/}
            {/*  <div className="flex ion-align-items-center">*/}
            {/*    <div className="inline-block shrink-0">*/}
            {/*      {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.VALUE ? (*/}
            {/*        <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/Messaging-2.svg" />*/}
            {/*      ) : (*/}
            {/*        <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/Messaging-1.svg" />*/}
            {/*      )}*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*      className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}*/}
            {/*    >*/}
            {/*      Messages*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</button>*/}

            {/**************************************************************/}
            {/* REPORTS */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.VALUE)
                }
              }}
              className={`min-w-[130px] w-full
                        ${setTabStyle(
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.VALUE,
                          CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {eventTab === CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/print-red.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/print.svg" />
                  )}
                </div>
                <div
                  className={`${eventTab !== CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Reports
                </div>
              </div>
            </button>
          </div>
        </div>
      </IonItem>

      <button
        className="flex items-center !text-white rounded-full py-1.5 mr-2 bg-SeabiscuitMainThemeColor hover:bg-[#d70443] px-5 text-sm h-10"
        onClick={openManageEventModal}
      >
        Manage
        <img src="/assets/og_icons/Create 4.svg" className="ml-2 w-5" alt="edit" />
      </button>
    </div>
  )
}

export default ManageClinicNOtherTabs
