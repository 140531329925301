import { Add, AddCircleOutline, HighlightOff } from '@mui/icons-material'
import React, { FC } from 'react'
import clsx from 'clsx'
import { Control, Controller, useFieldArray, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import Select from 'react-select'

import { customStyles } from '../../../components/customStyles/ReactSelectCustomStyle'
import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'

import { IOnRemoveImageButtonClickProps } from '../HorsePaperwork'

import { getReactPickerDate } from '../../../models/interface.helper'
import { horseHeightList } from '../../../fakeData/HorseHeightList'
import {
  IHorseCompetitorDocument,
  IMeasurementCard,
} from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../../models/horse/horse.interface'

import { CardType, HeelSize, ShodStatus } from '../../../fakeData/horse'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { IProfileReqField } from '../../competitor/clinicAndOther/Tabs/components/ProfileFields'

const horseMeasurementCardIniVal: IMeasurementCard = {
  horseShodStatus: null,
  horseMeasurementDate: null,
  horseHeightMeasurement: null,
  horseMeasurementCardType: null,
  horseLeftHeelMeasurement: null,
  horseRightHeelMeasurement: null,
  horseMeasurementCardsImage: [],
}

interface MeasurementCardProps {
  getImage: (image: string) => { url: string; type: string }
  handleManageToastConfirm: (
    index: number,
    type: IOnRemoveImageButtonClickProps['type'],
    rowIndex?: number
  ) => void
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
  selectedHorse: IHorseData | null
  watch: UseFormWatch<IHorseCompetitorDocument>
  control: Control<IHorseCompetitorDocument>
  saved: boolean
  imagesUrlsToDeleteFromStorage: any
  onSubmit: (data: any, keepUnsaved: boolean) => Promise<void>
  setValue: UseFormSetValue<IHorseCompetitorDocument>
  profileFiled?: IProfileReqField
}

export const MeasurementCard: FC<MeasurementCardProps> = ({
  saved,
  selectedHorse,
  control,
  watch,
  getImage,
  imagesUrlsToDeleteFromStorage,
  handleManageToastConfirm,
  handleModal,
  onSubmit,
  setValue,
  profileFiled,
}) => {
  const horseMeasurementCards = useFieldArray({
    control,
    name: 'horseMeasurementCards',
  })

  const onAddMeasurementCardClick = () => {
    if (!selectedHorse?.id || saved) return

    horseMeasurementCards.append(horseMeasurementCardIniVal)
  }

  const onRemoveMeasurementCardButtonClick = (index: number) => {
    imagesUrlsToDeleteFromStorage.current = [
      ...imagesUrlsToDeleteFromStorage.current,
      ...(watch('horseMeasurementCards')?.[index]?.horseMeasurementCardsImage ?? []),
    ]
    horseMeasurementCards.remove(index)
  }

  const onAddHorseMeasurementCardsClick = (rowIndex: number) => {
    if (!selectedHorse?.id || saved) return
    handleModal?.(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseMeasurementCardsImage',
      data: watch(),
      setter: async (_: any, imageUrls: string[]) => {
        setValue(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`, [
          ...(watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`) ?? []),
          ...imageUrls,
        ])
        await onSubmit(watch(), true)
      },
      profileFiled,
    })
  }

  return (
    <div className="flex gap-4">
      <div className="flex flex-col justify-between w-1/5 flex-shrink-0">
        <div className="flex items-start mt-2 w-full">
          <label className="flex items-center mr-4 mt-4 w-1/5 text-SeabiscuitDark200ThemeColor text-sm">
            <img src="/assets/img/dark/Ruler.png" className="mr-4 w-6" alt="measurementCard" />
            Measurement card
          </label>
        </div>

        {!saved && (
          <div
            onClick={onAddMeasurementCardClick}
            className="flex items-center cursor-pointer mt-4"
          >
            <AddCircleOutline className="text-SeabiscuitMainThemeColor" fontSize="small" />

            <p className="ml-2 text-SeabiscuitMainThemeColor text-sm">Add measurement card</p>
          </div>
        )}
      </div>

      <div className="wrapper flex flex-col gap-4 flex-grow py-2">
        {horseMeasurementCards.fields.map((field: any, index) => {
          return (
            <React.Fragment key={field.id}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  {watch(`horseMeasurementCards.${index}.horseMeasurementCardsImage`).length ? (
                    <div className="flex gap-2">
                      {watch(`horseMeasurementCards.${index}.horseMeasurementCardsImage`)?.map(
                        (item: string, imageIndex: number) => {
                          return (
                            <div
                              key={`horseMeasurementCardsImage${field.id}${imageIndex}`}
                              className={clsx('image-preview rounded-md w-24 h-24 z-[0] relative', {
                                border: getImage(item).type === 'image',
                              })}
                            >
                              <img
                                src={getImage(item).url}
                                onClick={() => window.open(item, '_blank')}
                                className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                                alt="horseMeasurementCardsImage"
                              />
                              {!saved ? (
                                <button
                                  onClick={() => {
                                    handleManageToastConfirm(
                                      imageIndex,
                                      'horseMeasurementCardsImage',
                                      index
                                    )
                                  }}
                                  type="button"
                                  className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center z-[1]"
                                >
                                  <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                                </button>
                              ) : null}
                            </div>
                          )
                        }
                      )}
                    </div>
                  ) : null}

                  {!saved ? (
                    <div
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() => onAddHorseMeasurementCardsClick(index)}
                    >
                      <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                        <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                      </div>
                      <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-wrap gap-2">
                  <Controller
                    name={`horseMeasurementCards.${index}.horseMeasurementCardType`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <label className="text-sm text-gray-400">
                          Card Type
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={CardType.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            onBlur={onBlur}
                            placeholder={<div className="flex text-[13px]">Select</div>}
                            options={CardType}
                            isDisabled={saved}
                            className={`border-[#D3DAEE] border rounded-md w-40 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </label>
                      )
                    }}
                  />

                  {/* Measurement date... */}

                  <Controller
                    name={`horseMeasurementCards.${index}.horseMeasurementDate`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <div className="flex flex-col">
                          <label className="text-sm text-gray-400">Measurement Date</label>
                          <CustomDatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={getReactPickerDate(value)}
                            peekNextMonth
                            dropdownMode="select"
                            wrapperClassName="w-auto"
                            showMonthDropdown
                            showYearDropdown
                            disabled={saved}
                            placeholderText="Select"
                            className={`rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 py-3 pl-10 !text-sm w-30 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor pt-2 placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                          />
                        </div>
                      )
                    }}
                  />

                  {/* Height measured... */}

                  <Controller
                    name={`horseMeasurementCards.${index}.horseHeightMeasurement`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <label className="text-sm text-gray-400">
                        Height Measured
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={horseHeightList.find(
                            (c) => c.value === ((value as any)?.value ?? value)
                          )}
                          onBlur={onBlur}
                          placeholder={<div className="flex text-[13px] p-0">Select</div>}
                          options={horseHeightList}
                          isDisabled={saved}
                          className={`border-[#D3DAEE] border rounded-md min-w-[125px] text-[2px] ${saved ? '!border-white  text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </label>
                    )}
                  />

                  {/* Left heel size... */}

                  <Controller
                    name={`horseMeasurementCards.${index}.horseLeftHeelMeasurement`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <label className="text-sm text-gray-400">
                        Left Heel Size
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={HeelSize.find((c) => c.value === ((value as any)?.value ?? value))}
                          onBlur={onBlur}
                          placeholder={<div className="flex text-[13px]">Select</div>}
                          options={HeelSize}
                          className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                          isClearable={false}
                          isDisabled={saved}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </label>
                    )}
                  />

                  {/* Right heel size... */}
                  <Controller
                    name={`horseMeasurementCards.${index}.horseRightHeelMeasurement`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <label className="text-sm text-gray-400">
                        Right Heel Size
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={HeelSize.find((c) => c.value === ((value as any)?.value ?? value))}
                          onBlur={onBlur}
                          placeholder={<div className="flex text-[13px]">Select</div>}
                          options={HeelSize}
                          isDisabled={saved}
                          className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </label>
                    )}
                  />

                  {/* Shod status... */}
                  <Controller
                    name={`horseMeasurementCards.${index}.horseShodStatus`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <label className="text-sm text-gray-400">
                        Shod Status
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={ShodStatus.find(
                            (c) => c.value === ((value as any)?.value ?? value)
                          )}
                          onBlur={onBlur}
                          placeholder={<div className="flex text-[13px]">Select</div>}
                          isDisabled={saved}
                          options={ShodStatus}
                          className={`border-[#D3DAEE] border rounded-md w-40 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </label>
                    )}
                  />

                  {!saved && index ? (
                    <div
                      onClick={() => onRemoveMeasurementCardButtonClick(index)}
                      className="cursor-pointer flex mt-[18px]"
                    >
                      <img src={'assets/og_icons/Cancel.svg'} alt="cancel" />
                    </div>
                  ) : null}
                </div>
              </div>

              {horseMeasurementCards.fields.length - 1 > index ? <hr className="w-full" /> : null}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
