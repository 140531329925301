import { cloneDeep } from 'lodash'

import FirestoreService from '../services/firestoreService'

import { GeneralLiabilityWaiverPDFTeamplate } from '../templates/pdf/generalLiabilityWaiver/GeneralLiabilityWaiverPDFTeamplate'
import { USDFLiabilityWaiverPDFTemplate } from '../templates/pdf/USDFLiabilityWaiver/USDFLiabilityWaiverPDFTemplate'
import { USEFLiabilityWaiverPDFTemplate } from '../templates/pdf/USEFLiabilityWaiver/USEFLiabilityWaiverPDFTemplate'
import { USEALiabilityWaiverPDFTeamplate } from '../templates/pdf/USEALiabilityWaiver/USEALiabilityWaiverPDFTeamplate'
import { USHJALiabilityWaiverPDFTeamplate } from '../templates/pdf/USHJALiabilityWaiverPDFTeamplate/USHJALiabilityWaiverPDFTeamplate'
import { USEFEntryAgreementPDFTemplate } from '../templates/pdf/USEFEntryAgreement/USEFEntryAgreementPDFTemplate'

import { IDocument } from '../models/event-registered-users/event-registered-users.interface'
import { IRiderTeamMemberInterface } from '../models/rider-team-member/riderTeamMember.interface'
import { IManageInfo } from '../pages/competitor/event-registration-tabs/EventRegistrationTabs'
import { UserModel } from '../models/users/user.model'
import { IEventDetails } from '../models/event-details/event-details.interface'
import { IUserInterface } from '../models/users/user.interface'
import { getConvertedData } from '../models/interface.helper'
import { RiderTeamMemberModel } from '../models/rider-team-member/riderTeamMember.model'

import { signedStatuses } from '../const/data/v01/rider-team-members/firestore-v01-rider-team-member-const'

import { CONST } from '../const/const'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'
import { getAllClauses } from './clauses'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

function calculateAge(birthday: any) {
  // birthday is a date
  let currentTime = new Date().getTime()
  let birthDateTime = new Date(birthday).getTime()
  let difference = currentTime - birthDateTime
  var ageInYears = difference / (1000 * 60 * 60 * 24 * 365)
  return Math.floor(ageInYears)
}

interface EligibleToSignProps {
  paperworkDocuments: IDocument[]
  ridersTeamMembers: IRiderTeamMemberInterface[]
  userId: string
  manageInfo?: IManageInfo
  isManage?: boolean
}

export const eligibleToSign = ({
  paperworkDocuments,
  ridersTeamMembers,
  isManage,
  manageInfo,
  userId,
}: EligibleToSignProps) => {
  let notSignedOwner = false
  const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
  let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

  if (isManage) {
    manageInfo?.paperwork.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0)
        ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
    })

    ridersTeamMembers_ = ridersTeamMembersManage
  }

  for (const paperworkDocument of paperworkDocuments) {
    for (const currRiderTeamMember of ridersTeamMembers_) {
      if (currRiderTeamMember.teamMemberId === userId) {
        const isSigned = currRiderTeamMember.mailLog.find(
          (currMailLogItem) =>
            currMailLogItem.paperworkKey === paperworkDocument.key &&
            currMailLogItem.status === signedStatuses.SIGNED
        )
        if (!isSigned?.paperworkKey) {
          notSignedOwner = true
          break // exits the inner loop
        }
      }
    }
    if (notSignedOwner) break // exits the outer loop
  }

  return notSignedOwner
}

export const generatePaperworkDocument = async ({
  paperwork,
  currRidersTeamMember,
  registrationByDay,
  userData,
  eventId,
  EventDetails,
}: {
  paperwork: IDocument
  currRidersTeamMember: IRiderTeamMemberInterface
  registrationByDay: IRegistrationByDayInterface
  userData: IUserInterface
  eventId: string
  EventDetails: IEventDetails
}) => {
  const doc = await FirestoreService.getItem(
    CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
    EventDetails.owner ?? ''
  )

  if (!doc) throw new Error('Error getting owner user doc')

  const organizerDetails = UserModel.fromFirestoreDoc(doc).toObject()

  const data = {
    eventId,
    userData,
    paperwork,
    EventDetails,
    organizerDetails,
    eventDraftId: '',
  }

  const allClauses = await getAllClauses(EventDetails.owner ?? '')

  let age = calculateAge(currRidersTeamMember?.teamMemberDob || userData.userDOB)
  const stringArray = [currRidersTeamMember.teamMemberRole ?? '']
  const index = 0

  console.log(registrationByDay, 'registrationByDay')

  switch (paperwork.document_type) {
    case 'general':
      await GeneralLiabilityWaiverPDFTeamplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        clauses: allClauses,
        isSigned: true,
        stringArray,
        index,
      })
      break

    case 'usdf':
      await USDFLiabilityWaiverPDFTemplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        isSigned: true,
        stringArray,
        index,
      })
      break

    case 'usef':
      await USEFLiabilityWaiverPDFTemplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        isSigned: true,
        stringArray,
        index,
      })
      break

    case 'usea':
      await USEALiabilityWaiverPDFTeamplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        isSigned: true,
        stringArray,
        index,
      })
      break

    case 'ushja':
      await USHJALiabilityWaiverPDFTeamplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        isSigned: true,
        stringArray,
        index,
      })
      break

    case 'usefentry':
      await USEFEntryAgreementPDFTemplate({
        ...data,
        ridersTeamMember: currRidersTeamMember,
        registrationByDay: registrationByDay,
        isMature: age > 18,
        isSigned: true,
        stringArray,
        index,
      })
      break
  }
}

export const createMailLog = async ({
  ridersTeamMembers,
  teamMemberId,
  paperworkDocument,
}: {
  ridersTeamMembers: IRiderTeamMemberInterface[]
  teamMemberId: string
  paperworkDocument: IDocument
}) => {
  let mailLog: IRiderTeamMemberInterface['mailLog'] = []
  let mailLogItemIndex = -1

  return ridersTeamMembers
    .filter((member) => member.teamMemberId === teamMemberId)
    .map((currRiderTeamMember) => {
      mailLog = cloneDeep(currRiderTeamMember.mailLog)

      mailLogItemIndex = mailLog.findIndex((curMailLog) => {
        return curMailLog.paperworkKey === paperworkDocument.key
      })

      if (mailLogItemIndex === -1) {
        mailLog.push({
          paperworkKey: paperworkDocument.key,
          status:
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.SIGNED_STATUS.VALUES.SIGNED ??
            signedStatuses.NOT_SIGNED,
        })
      } else {
        mailLog[mailLogItemIndex] = {
          ...mailLog[mailLogItemIndex],
          paperworkKey: paperworkDocument.key,
          status:
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.SIGNED_STATUS.VALUES.SIGNED ??
            signedStatuses.NOT_SIGNED,
        }
      }

      return getConvertedData({
        ...new RiderTeamMemberModel({
          ...currRiderTeamMember,
          mailLog,
        }).toObject(),
      })
    })
}
