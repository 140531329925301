// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonItem, IonList, IonPopover } from '@ionic/react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CONST } from '../../../../const/const'
import { setActiveFilters } from '../../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setUserMode } from '../../../../store/system/systemSlice'
import {
  clearUserStoredData,
  selectUserNameAc,
  selectUserProfileImageUrl,
  selectProfileData,
} from '../../../../store/user/userSlice'
import './TopNavigationRightMenuCommonComponent.css'
import FirebaseApp from '../../../../services/firebaseApp'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { useIntercom } from 'react-use-intercom'
import { ChannelList, InfiniteScroll, InfiniteScrollProps } from 'stream-chat-react'
import { ChannelSort, ChannelFilters, ChannelOptions } from 'stream-chat'
import ChatHOC from '../../../../pages/messages/components/ChatHOC'
import CustomChannelPreview from '../../../../pages/messages/components/CustomChannelPreview'
import { useHistory } from 'react-router-dom'
import { IconRulebook } from '../../../icons/IconRulebook'
import { IconMessaging } from '../../../icons/IconMessaging'
import { IconNotification } from '../../../icons/IconNotification'
import { IonSpinner } from '@ionic/react'
import './style.css'

const Paginator = (props: InfiniteScrollProps) => <InfiniteScroll {...props} threshold={50} />
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface TopNavigationRightMenuCommonComponentProps {}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const TopNavigationRightMenuCommonComponent: React.FC<
  TopNavigationRightMenuCommonComponentProps
> = (props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const userName = useAppSelector(selectUserNameAc)
  const userProfileImageUrl = useAppSelector(selectUserProfileImageUrl)
  const profileData = useAppSelector(selectProfileData)
  const userId = profileData.id
  const history = useHistory()
  const handleModal = useContext(HandleModalContext)?.handleModal
  const { show } = useIntercom()
  const [displayMenu, setDisplayMenu] = useState(false)
  const [messagesMenu, setMessagesMenu] = useState(false)
  const [comingSoon, setComingSoon] = useState(false)
  const [comingSoonMessage, setComingSoonMessage] = useState('Coming Soon...')
  const [helpMessage, setHelpMessage] = useState(false)
  const helpMessageText = (
    <p>
      Found a bug? Help us squash them! Report it in the{' '}
      <a onClick={show} className="underline cursor-pointer">
        Help Center
      </a>
      👇
    </p>
  )

  const messages_filters: ChannelFilters = {
    type: 'messaging',
    members: { $in: [userId] },
  }
  const options: ChannelOptions = { state: true, presence: true, limit: 15 }
  const sort: ChannelSort = { updated_at: -1 }

  const onSwithAccountOptionClick = () => {
    setDisplayMenu(false)
    handleModal?.(true, MODAL_CONSTS.SWITCH_ACCOUNT)
  }

  const handleLogout = async () => {
    dispatch(setUserMode(null))
    dispatch(clearUserStoredData(true))
    dispatch(setActiveFilters(null))
    await FirebaseApp.auth.signOut()
    window.location.replace(CONST.ROUTES.LOGOUT.URL)

    if (0) console.log({ onSwithAccountOptionClick })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div id="" className="flex-shrink-0 block">
        <div className="flex items-center">
          <>
            <div
              className="mr-4 text-[#9ca2b5] rounded-lg p-1 px-4 border-[1px] border-[#9ca2b5] cursor-pointer"
              onClick={() => {
                setHelpMessage(true)
                setComingSoon(true)
              }}
            >
              BETA
            </div>

            <button
              onClick={() => {
                handleModal && handleModal(true, MODAL_CONSTS.AI_RULE_BOOK)
              }}
              className="group relative cursor-pointer mr-3"
            >
              <IconRulebook />
            </button>

            <button
              className="group relative cursor-pointer"
              id="messages-menu"
              onClick={() => {
                setMessagesMenu(true)
              }}
            >
              <IconMessaging />
            </button>
            <button
              className="group mx-3 relative cursor-pointer"
              onClick={() => {
                setComingSoonMessage('Push notifications coming soon')
                setComingSoon(true)
              }}
              id="notification-menu"
            >
              <IconNotification />
            </button>
          </>

          <div
            className="rounded-full"
            onClick={() => {
              setDisplayMenu(true)
            }}
            id="right-side-menu"
          >
            <img
              className="inline-block rounded-full p-1"
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              src={!!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER}
              onError={(e) => {
                return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
              }}
              alt=""
            />
          </div>
          <span
            onClick={() => {
              setDisplayMenu(true)
            }}
          ></span>
        </div>
      </div>

      <IonPopover
        isOpen={displayMenu}
        reference="trigger"
        trigger="right-side-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => setDisplayMenu(false)}
        style={{ '--border-radius': '40px' }}
      >
        <IonList style={{ borderRadius: '40px !important' }}>
          <IonItem className="flex items-center capitalize">
            <div className="flex items-center capitalize">
              <div className="rounded-full bg-[#F0F3FA] flex justify-center items-center mr-4">
                <Link to="/allevent" className="cursor-pointer block w-[35px] h-[35px]">
                  <img
                    className="inline-block w-full h-full rounded-full object-cover"
                    src={
                      !!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER
                    }
                    onError={(e) => {
                      return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
                    }}
                    alt=""
                  />
                </Link>
              </div>
              <div className="cursor-pointer flex item-center">{userName}</div>
            </div>

            <div
              style={{
                content: '""',
                position: 'fixed',
                bottom: '56px',
                left: '20px',
                right: '20px',
                height: '1px',
                background: '#000',
              }}
            ></div>
          </IonItem>

          <IonItem className="cursor-pointer" onClick={onSwithAccountOptionClick}>
            Switch
          </IonItem>

          <IonItem
            className="cursor-pointer"
            style={{ '--border-style': 'none' }}
            onClick={handleLogout}
          >
            Logout
          </IonItem>
        </IonList>
      </IonPopover>

      <IonPopover
        isOpen={messagesMenu}
        reference="trigger"
        trigger="messages-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => {
          setMessagesMenu(false)
        }}
        style={{
          '--border-radius': '40px',
          '--min-width': '419px',
          '--max-height': '412px',
        }}
      >
        <div className="h-[412px] overflow-y-scroll no-scrollbar p-[10px] flex flex-col">
          <h2 className="text-xl py-2 px-4">Messages</h2>
          <ChatHOC>
            <ChannelList
              filters={messages_filters}
              options={options}
              sort={sort}
              LoadingIndicator={() => (
                <div className="flex flex-1 justify-center items-center">
                  <IonSpinner name="crescent" className="mx-auto" />
                </div>
              )}
              EmptyStateIndicator={() => (
                <div className="flex flex-1 h-full justify-center items-center">
                  <button onClick={() => history.push(`${CONST.ROUTES.MESSAGES.URL}`)}>
                    New Message
                  </button>
                </div>
              )}
              Preview={(props) => (
                <CustomChannelPreview
                  {...props}
                  onSelect={() => {
                    setMessagesMenu(false)
                    history.push(`${CONST.ROUTES.MESSAGES.URL}`, { channelId: props.channel.id })
                  }}
                  className="cursor-pointer border-b-[1px] border-[#F6F7FB] bg-transparent"
                  editable={false}
                  key={props.channel.id}
                />
              )}
              Paginator={Paginator}
            />

            <button
              onClick={() => history.push(`${CONST.ROUTES.MESSAGES.URL}`)}
              className="w-full p-2"
            >
              <span className="text-xs">See all &gt;</span>
            </button>
          </ChatHOC>
        </div>
      </IonPopover>

      <IonPopover
        isOpen={comingSoon}
        reference="trigger"
        trigger="notification-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => {
          setHelpMessage(false)
          setComingSoon(false)
        }}
      >
        <span>
          <h2 className="text-SeabiscuitDarkMainThemeColor  bold p-4">
            {helpMessage ? helpMessageText : comingSoonMessage}
          </h2>
        </span>
      </IonPopover>
    </>
  )
}

TopNavigationRightMenuCommonComponent.defaultProps = {}

export default TopNavigationRightMenuCommonComponent
