import React from 'react'
import { CommentsQuestion } from './components/CommentsQuestion'

interface IProps {}

export const ManageClinicNOtherCommentsTab: React.FC<IProps> = () => {
  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-[100%]">
      <div className="flex flex-col">
        <div className="border-b border-1 border-[#D3DAEE] w-full pb-5">
          <h2 className={'text-[#F7074F] text-[25px] font-bold leading-none'}>Comments</h2>
          <p className={'text-[#122B46]'}>Add comments for show management</p>
        </div>
        <div className={'flex gap-8 w-full mt-8'}>
          <CommentsQuestion
            questionId={''}
            title={''}
            handleChange={() => null}
            addFile={() => null}
            files={[]}
            removeFile={() => null}
          />
        </div>
      </div>
    </div>
  )
}
