import React, { useRef, useState } from 'react'
import { IHorseData } from '../../../../models/horse/horse.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import { StepTitle } from './StepTitle'

interface IProps {
  horses: IHorseData[]
  selectedHorse: IHorseData | null
  selectHandler: (itm: IHorseData) => void
}
const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

export const SelectNewHorse: React.FC<IProps> = ({ horses, selectHandler, selectedHorse }) => {
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [listToShow, setListToShow] = useState<IHorseData[]>([])

  React.useEffect(() => {
    if (selectedHorse) {
      setSearchValue(selectedHorse.horseName || '')
      filterHorses(selectedHorse.horseName || '')
    }
  }, [])
  const handleHorses = (strToSearch: string) => {
    if (strToSearch === '') {
      setListToShow(horses)
    }
    const matched = horses.filter((horse) => {
      return horse?.horseName?.toLowerCase().includes(strToSearch.toLowerCase())
    })

    setListToShow(matched)
  }

  const filterHorses = (strToSearch: string) => {
    setSearchValue(strToSearch)
    handleHorses(strToSearch)
  }
  return (
    <>
      <StepTitle title={'Select new horse'} desc={''} />
      <div className="inputSearch flex items-center relative">
        <input
          ref={searchBoxRef}
          type="text"
          onChange={(e) => filterHorses(e.target.value)}
          value={searchValue}
          placeholder="Search horse name"
          className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
        />
      </div>

      <div>
        {searchValue &&
          listToShow[0] &&
          listToShow.map((horse) => {
            const selected = selectedHorse && selectedHorse.id === horse.id
            return (
              <div
                onClick={() => selectHandler(horse)}
                key={horse.id}
                className="form-check mb-3 cursor-pointer"
              >
                <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                  <div className="min-w-fit flex items-center">
                    {customImageComponent(
                      horse.horseProfilePicture,
                      horse.horseName,
                      'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                    )}
                    <div className="hourseDetails w-10/12">
                      <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                        <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                          {horse.horseName}
                        </p>
                      </div>
                    </div>
                  </div>

                  {selected ? (
                    <img
                      src={CHECKED_ICON}
                      className="relative right-[0px] top-[0px]"
                      width={20}
                      height={20}
                      alt="checked"
                    />
                  ) : null}

                  {/*<input*/}
                  {/*  onChange={(_e) => {*/}
                  {/*    // console.log(e)*/}
                  {/*    selectHandler(horse);*/}
                  {/*  }}*/}
                  {/*  // checked={!!condition}*/}
                  {/*  // disabled={!!condition}*/}
                  {/*  id={horse.id}*/}
                  {/*  className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}*/}
                  {/*  type="checkbox"*/}
                  {/*/>*/}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
