import React from 'react'
import CompetitorEventRegisterWrapper from '../CompetitorEventRegisterWrapper'
import { CommentsQuestion } from '../../../organizer/manage/clinic/components/CommentsQuestion'
import { IEventInterface, IQuestion } from '../../../../models/events/event.interface'
import { IComment } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { useAppDispatch } from '../../../../store/hooks'
import { setRegistrationComments } from '../../../../store/registration/registrationSlice'

interface IProps {
  event: IEventInterface | null | undefined
  comments: IComment[]
}

export const CompetitorEventRegisterComments: React.FC<IProps> = ({ event, comments }) => {
  const dispatch = useAppDispatch()
  const newComments = [...comments]

  const handlerChangeComment = (value: string, question: IQuestion, index: number) => {
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex >= 0) {
      newComments[currentCommentIndex] = {
        ...newComments[currentCommentIndex],
        comment: value,
      }

      dispatch(setRegistrationComments(newComments))
      return null
    }

    const newData = [...newComments]

    newData.push({
      comment: value,
      id: `${new Date().getTime()}`,
      questionId: question.id,
      files: [],
    })

    dispatch(setRegistrationComments(newData))
    return null
  }

  const addFile = (value: string, question: IQuestion, index: number) => {
    console.log('=>(CompetitorEventRegisterComments.tsx:46) value', value)
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex >= 0) {
      newComments[currentCommentIndex] = {
        ...newComments[currentCommentIndex],
        files: [...newComments[currentCommentIndex].files, value],
      }

      dispatch(setRegistrationComments(newComments))
      return null
    }

    const newData = [...newComments]

    newData.push({
      comment: '',
      id: `${new Date().getTime()}`,
      questionId: question.id,
      files: [value],
    })

    dispatch(setRegistrationComments(newData))
    return null
  }

  const removeFile = (index: number, question: IQuestion) => {
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex < 0) {
      return null
    }
    const newData = [...newComments]

    const newFiles = [...newData[currentCommentIndex].files]
    newFiles.splice(index, 1)

    newData[currentCommentIndex] = {
      ...newData[currentCommentIndex],
      files: newFiles,
    }

    dispatch(setRegistrationComments(newData))
  }
  const renderBody = () => {
    if (!event) {
      return <>No event data</>
    }

    if (!Array.isArray(event.questions) || !event.questions[0]) {
      return <h3>No questions</h3>
    }

    return event.questions?.map((question, index) => {
      const currentComment = comments.find((c) => c.questionId === question.id)

      return (
        <div key={question.id} className={'flex gap-8 w-full mt-8'}>
          <CommentsQuestion
            addFile={(v) => addFile(v, question, index)}
            files={currentComment?.files || []}
            questionId={question.id}
            defaultValue={currentComment?.comment || ''}
            handleChange={(value) => handlerChangeComment(value, question, index)}
            title={question.value}
            removeFile={(v, i) => removeFile(i, question)}
          />
        </div>
      )
    })
  }

  return (
    <CompetitorEventRegisterWrapper
      title={'Comments'}
      description={'Add comments for show management'}
    >
      {renderBody()}
    </CompetitorEventRegisterWrapper>
  )
}
