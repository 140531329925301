import React, { useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { where } from 'firebase/firestore'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import ViewsLoader from '../../../components/loader/ViewsLoader'

interface IProps {
  userId: string
}

export const ExhibitorProfileAnswersTab: React.FC<IProps> = ({ userId }) => {
  const event = useAppSelector(selectedEvent)
  const [registeredUser, setRegisteredUser] = useState<IRegistrationByDayInterface | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const currentEvent = event?.Event

  React.useEffect(() => {
    ;(async () => {
      try {
        const registrationByDaySnap = await FirestoreService.filterItems(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          [
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY,
              '==',
              event?.Event?.id || ''
            ),
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY,
              '==',
              userId
            ),
          ]
        )

        let registrationByDay = registrationByDaySnap?.docs.map((doc: any) => {
          return RegistrationByDayModel.fromFirestoreDoc(doc).toObject()
        })
        const amRegisteredUser = registrationByDay.find((r) => r.riderId === userId)

        if (amRegisteredUser) setRegisteredUser(amRegisteredUser)
      } catch (e) {
        console.log('=>(AnswersModal.tsx:34) e', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return (
      <div className={'w-full h-full relative min-h-[60vh]'}>
        <ViewsLoader
          isPageLoader={true}
          className="absolute !w-[100%] !h-[100%] mt-[-5vh]"
          size="sm"
          color="red"
        />
      </div>
    )
  }
  if (!currentEvent) {
    return <>No Event Found</>
  }
  if (!Array.isArray(currentEvent.questions) || !currentEvent.questions[0]) {
    return <h2>Does not have questions</h2>
  }
  if (!registeredUser) {
    return <h2>Rider is not selected</h2>
  }

  return (
    <div className={'flex flex-col gap-6 mt-4'}>
      {currentEvent.questions.map((q) => {
        const qc = registeredUser?.comments?.filter((c) => c.questionId === q.id) || []

        return (
          <div className={q.id}>
            <h3 className={'text-[#122B46] text-[16px] mb-4 font-bold'}>{q.value}</h3>
            {qc.map((cc) => (
              <div className={'bg-[#F6F7FB] rounded-lg'}>
                <div className={'p-4 text-[#122B46]'}>
                  <p>{cc.comment}</p>
                </div>
                <div
                  className={
                    'p-4 border-t-[1px] border-solid border-[#D3DAEE] flex gap-3 flex-wrap'
                  }
                >
                  {cc.files.map((f, index) => (
                    <a
                      key={index + f + q.id}
                      href={f}
                      rel="noreferrer"
                      target={'_blank'}
                      className={'w-[45px] h-[45px] bg-[#D3DAEE] rounded-lg'}
                    >
                      <img
                        className={'w-[45px] h-[45px] rounded-lg'}
                        src={f}
                        alt={`img-${index}`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
