import { useEffect, useState } from 'react'
import { IconPlusCircle } from '../../../components/icons/IconPlusCircle'
import { IPaperworkTeam } from '../../../store/paperworks/paperworkSlice'
import { AccordionWrapper } from './AccordionWrapper'
import customImageComponent from '../../../components/common/CustomImageComponent'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { signedStatuses } from '../../../const/data/v01/rider-team-members/firestore-v01-rider-team-member-const'

interface IPropsHorseAccordion {
  accordionIndex: number
  team: IPaperworkTeam[]
  eventId: string | null
  addSignerHandler: () => void
  registrationByDay: IRegistrationByDayInterface
}

export const HorseAccordionWrapper = ({
  team,
  eventId,
  addSignerHandler,
  registrationByDay,
}: IPropsHorseAccordion) => {
  const [isOpen, setIsOpen] = useState(true)
  const [openedMenu, setOpenedMenu] = useState<number[]>([])
  const [signMissing, setSignMissing] = useState(0)

  useEffect(() => {
    const totalProblemsFound = team.reduce((sum, item) => {
      if (item.documents && item.documents?.length > 0) {
        const notSigned = item.documents.filter(
          (document) => document.status === signedStatuses.NOT_SIGNED
        )
        return sum + notSigned.length
      } else {
        return sum
      }
    }, 0)
    setSignMissing(totalProblemsFound)
  }, [team])

  const handleToggle = (value: number) => {
    const exist = openedMenu.includes(value)

    if (exist) {
      setOpenedMenu(() => {
        return openedMenu.filter((current) => {
          return current !== value
        })
      })
    } else {
      setOpenedMenu((prevState) => [...prevState, value])
    }
  }

  const [uniqueRoles, setUniqueRoles] = useState<string[]>([])

  useEffect(() => {
    const roles: string[] = []
    if (team) {
      team.forEach((item) => {
        if (item?.teamMemberRole && !roles.includes(item?.teamMemberRole)) {
          roles.push(item.teamMemberRole)
        }
      })

      setUniqueRoles(roles)
    }
  }, [team])

  return (
    <>
      <div className="mt-2 border-[#D3DAEE] border px-4 py-2 pl-2 rounded-lg transition-all">
        <div className="text-SeabiscuitDark200ThemeColor">
          <div
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className={
              'flex flex-col lg:flex-row lg:justify-between lg:items-center font-semibold accordionParent cursor-pointer'
            }
          >
            <div>
              <div className="flex items-center">
                <span className="w-10 h-10 rounded-md overflow-hidden mr-4">
                  {customImageComponent(
                    registrationByDay?.horseProfilePicture,
                    registrationByDay?.horseName,
                    'w-full h-full object-cover'
                  )}
                </span>
                <div className="flex flex-col">
                  <span className="text-md text-SeabiscuitDark200ThemeColor font-semibold capitalize">
                    {registrationByDay?.horseName || 'N/A'}
                    <div className="font-normal text-[12px] text-[#3C4B71B2]">
                      {team ? team.length : 0} team member
                      {team && team.length > 1 && 's'} • {uniqueRoles ? uniqueRoles.length : 0} role
                      {(uniqueRoles && uniqueRoles.length > 1) || !uniqueRoles ? 's' : ''}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-3 lg:mt-0 items-center">
              <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
                {signMissing === 0
                  ? 'All paperwork signed'
                  : `${signMissing} ${signMissing === 1 ? 'signature' : 'signatures'} missing`}
              </div>
              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4 add_icon cursor-pointer">
                {isOpen ? (
                  <div className={'add_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                  </div>
                ) : (
                  <div className={'remove_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`faqs-content my-2 ${isOpen ? 'activeTab' : ''}`}>
            {team &&
              team.map((paperworkMember, i) => {
                return (
                  <AccordionWrapper
                    key={i}
                    openedMenu={openedMenu}
                    handleToggle={handleToggle}
                    accordionIndex={i}
                    userData={paperworkMember}
                    eventId={eventId}
                    cbSingSuccess={() => setSignMissing((v) => v - 1)}
                    registrationByDay={registrationByDay}
                  />
                )
              })}

            <div>
              <button
                className={'mt-3 cursor-pointer mx-auto gap-2 flex items-center text-[#122B4666]'}
                onClick={addSignerHandler}
              >
                <IconPlusCircle />
                <span className={'underline'}>Add signer</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
