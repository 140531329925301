import React, { useEffect, useRef, useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MainModal from '../../modals/common/MainModal'

import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import { selectProfileData } from '../../../store/user/userSlice'
import customImageComponent from '../../common/CustomImageComponent'
import ViewsLoader from '../../loader/ViewsLoader'
import { IconPlus } from '../../icons/IconPlus'
import FirestoreService from '../../../services/firestoreService'

import { CONST } from '../../../const/const'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    authorizeUser: IUserExtendedWithSelected[]
    authorizeuserHorses: IUserHorseMappingInterface[]
  }
}

interface IUserExtendedWithSelected extends ITeamMember {
  selected?: boolean
  status?: string
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const RegistrationTeamTabModal = (props: Props) => {
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<IUserExtendedWithSelected[]>()
  const [users, setUsers] = useState<IUserInterface[] | null>(null)

  const dispatch = useAppDispatch()
  const selectProfileDetails = useAppSelector(selectProfileData)
  const teamMembers = useAppSelector(selectTeamTabData)
  const myHorses = useAppSelector(selectHorses)

  const { authorizeUser, authorizeuserHorses } = props.dataToPassOn

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') {
      setListToShow(authorizeUser)
    }
    const matched = authorizeUser.filter((user: IUserExtendedWithSelected) => {
      return (
        user.memberName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        user.memberUserName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        user.memberEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
        // &&
        // (user.status === 'authorized' || user.status === 'Authorized')
      )
    })

    setListToShow(matched)
  }

  useEffect(() => {
    console.log('=>(RegistrationTeamTabModal.tsx:72) props.dataToPassOn', props.dataToPassOn)
    if (authorizeUser) {
      const matched = authorizeUser.filter((user: IUserExtendedWithSelected) => {
        return user.status === 'authorized' || user.status === 'Authorized'
      })
      setListToShow(matched)
    }
  }, [authorizeUser])

  const getUsers = async () => {
    let users_: IUserInterface[] = []

    const userSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

    userSnaps.forEach((currDoc) => {
      users_.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    setUsers(users_)
  }
  useEffect(() => {
    getUsers().then()
  }, [])

  async function addTeamMembersToViewOnly() {
    const filter = listToShow?.filter((data) => data.selected === true)
    const teamData: IRegistrationTeamInterface[] = []
    let selectedHorseData: IUserHorseMappingInterface[] = []

    try {
      if (!filter?.length) return

      filter?.forEach((data) => {
        const checkTrue = teamMembers.find((tabdata) => tabdata.memberId === data.memberId)
        if (!checkTrue && users) {
          const teamMemberUser = users.find((user) => user.id === data.memberId)
          if (teamMemberUser)
            teamData.push(
              getConvertedData(getSelectedUserAsTeamMember(teamMemberUser, selectProfileDetails))
            )
        }
      })

      if (!teamData?.length) return

      teamData.forEach((data) => {
        const horse = authorizeuserHorses.filter(
          (horseData: any) => horseData.horseOwnerId === data.memberId
        )
        if (horse) {
          selectedHorseData = [...horse]
        }
      })

      dispatch(setTeamTabData([...teamMembers, ...teamData]))

      if (selectedHorseData.length) {
        dispatch(setHorses([...myHorses, ...selectedHorseData]))
      }
    } catch (error) {
    } finally {
      props.handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
    }
  }

  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value
    handleUsers(strToSearch)
  }

  const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    team_member: IUserExtendedWithSelected
  ) => {
    const select = e.target.checked

    if (listToShow) {
      const sameTypeUsersArrCopy = listToShow.map((currUser) => {
        if (currUser.memberId === team_member?.memberId) {
          currUser.selected = select
          return currUser
        } else {
          return currUser
        }
      })
      setListToShow(sameTypeUsersArrCopy)
    }
  }

  return (
    <>
      <MainModal
        title="Add team member"
        show={props.show}
        type="REGISTER_TEAM"
        size="md"
        onTransitionEnd={focusInput}
        titleClassName="!font-normal"
        buttons={[
          {
            label: 'SAVE',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: addTeamMembersToViewOnly,
            className: 'outline-none',
            textClass: 'text-white',
            disabled: !listToShow?.length,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => props.handleModal(false, MODAL_CONSTS.REGISTER_TEAM),
          },
        ]}
      >
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={filterUsers}
            placeholder="Search.."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          {/*<Search className="absolute text-SeabiscuitMainThemeColor right-4" />*/}
          <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
            <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
            <button
              onClick={() => {
                props.handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
                props.handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
              }}
              className={
                'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-[#f7074f]'
              }
            >
              <IconPlus /> New Person
            </button>
          </div>
        </div>
        {listToShow?.length ? (
          <>
            <div className="uselListWarp mt-4 flex flex-col">
              {listToShow?.length ? (
                listToShow?.map((item, index) => {
                  let condition = teamMembers.find((data) => data.memberId === item.memberId)
                  return (
                    <label
                      key={index}
                      className="form-check mb-3 cursor-pointer"
                      htmlFor={'teammember' + index}
                    >
                      <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                        <div className="min-w-fit flex items-center">
                          {customImageComponent(
                            item.memberProfilePicture,
                            item.memberName,
                            'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                          )}
                          <div className="hourseDetails w-10/12">
                            <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                              <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                                {item.memberName}
                              </p>
                              <p className="text-xs">
                                {item?.defaultRole}, {item?.memberEmail}
                              </p>
                            </div>
                          </div>
                        </div>

                        {item.selected || condition ? (
                          <img
                            src={CHECKED_ICON}
                            className="relative right-[0px] top-[0px]"
                            width={20}
                            height={20}
                            alt="checked"
                          />
                        ) : null}

                        <input
                          onChange={(e) => {
                            handleChange(e, item)
                          }}
                          // checked={!!condition}
                          disabled={!!condition}
                          id={'teammember' + index}
                          className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}
                          type="checkbox"
                        />
                      </div>
                    </label>
                  )
                })
              ) : (
                <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor"></div>
              )}
            </div>
          </>
        ) : (
          <>
            {!!searchBoxRef?.current?.value?.length && !listToShow ? (
              <>No data found</>
            ) : (
              <>
                {!!searchBoxRef?.current?.value?.length && !listToShow ? (
                  <ViewsLoader size="md" color="red" className="mt-1 pt-1" />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </MainModal>
    </>
  )
}

export default RegistrationTeamTabModal
