import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import {
  IPaperworkTab_,
  IRegisterTab,
  IRegistrationTabs,
  TEventRegisteredUsers,
} from '../../models/event-registered-users/event-registered-users.interface'
import {
  IComment,
  IRegistrationByDayInterface,
} from '../../models/registrations-by-day/registrationByDay.interface'
import { RootState } from '../store'
import IConsts from './registrationSlice.const'

import { IDocumentList } from '../../fakeData/fakeDocumentList'
import { IRecipientInterface } from '../../models/recipients/recipients.interface'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import ITypes from './registrationSlice.types'

const registrationSlice = createSlice({
  name: 'registration',
  initialState: IConsts.INITIAL_STATE,
  reducers: {
    setRegisterTabData: (state, { payload }: { payload: IRegisterTab[] }) => {
      state.tabs.registerTab = payload
    },
    setRegistrationComments: (state, { payload }: { payload: IComment[] }) => {
      state.data.registrationsByDay[0].comments = payload
    },
    setHorseRegistrationErrors: (state, { payload }: { payload: boolean }) => {
      state.hasErrors.horses = payload
    },
    setUserRegistrationErrors: (state, { payload }: { payload: boolean }) => {
      state.hasErrors.users = payload
    },
    // setRegistrationHasErrors: (
    //   state,
    //   {
    //     payload,
    //   }: {
    //     payload: {
    //       horseErrors: boolean
    //       userErrors: boolean
    //     }
    //   }
    // ) => {
    //   state.hasErrors = payload
    // },
    setTicketTabData: (state, { payload }: { payload: IRegistrationTabs['ITicketTab'][] }) => {
      state.tabs.ticketTab = payload
    },
    setTeamTabData: (state, { payload }: { payload: IRegistrationTabs['ITeamTab'] }) => {
      state.tabs.teamTab = payload
    },
    setFeesTabDataAc: (state, { payload }: { payload: IRegistrationTabs['IFeesTab'][] }) => {
      state.tabs.feesTab = payload
    },
    setRegistrationsByDay: (state, { payload }: { payload: IRegistrationByDayInterface[] }) => {
      state.data.registrationsByDay = payload
    },
    setRegistrationsTicketsAc: (
      state,
      { payload }: { payload: IRegistrationTicketInterface[] }
    ) => {
      state.data.registrationsTickets = payload
    },
    setRegistrationsFeesAc: (state, { payload }: { payload: IRegistrationFeesInterface[] }) => {
      state.data.registrationsFees = payload
    },
    setRegistrationLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload
    },
    updateRegistrationByDayInRedux: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdateRegistrationByDayInRedux']
      }
    ) => {
      let registrationsByDay: IRegistrationByDayInterface | null

      let { dataToUpdate, registrationByDayIndex, registrationTabRowIndex } = payload

      registrationsByDay =
        current(
          state.tabs.registerTab[registrationTabRowIndex].registrationsByDay[registrationByDayIndex]
        ) ?? null

      state.tabs.registerTab[registrationTabRowIndex].registrationsByDay[registrationByDayIndex] = {
        ...registrationsByDay,
        ...dataToUpdate,
      }
    },
    setRegistrationAc: (state, { payload }: { payload: TEventRegisteredUsers | null }) => {
      state.data.registration = payload
    },
    updateTicketTabRowInReduxAc: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdateTicketTabRowInRedux']
      }
    ) => {
      let { dataToUpdate, ticketTabRowIndex } = payload

      if (state.tabs.ticketTab[ticketTabRowIndex].registrationTicket && dataToUpdate)
        state.tabs.ticketTab[ticketTabRowIndex].registrationTicket = {
          ...state.tabs.ticketTab[ticketTabRowIndex].registrationTicket,
          ...dataToUpdate,
        }
      else {
        state.tabs.ticketTab[ticketTabRowIndex].registrationTicket = dataToUpdate
      }
    },
    updateFeesTabRowInReduxAc: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdateFeesTabRowInRedux']
      }
    ) => {
      let { dataToUpdate, feesTabRowIndex } = payload

      if (state.tabs.feesTab[feesTabRowIndex]?.registrationFees && dataToUpdate)
        state.tabs.feesTab[feesTabRowIndex].registrationFees = {
          ...state.tabs.feesTab[feesTabRowIndex].registrationFees,
          ...dataToUpdate,
        }
      else {
        state.tabs.feesTab[feesTabRowIndex].registrationFees = dataToUpdate
      }
    },
    updatePayTabRegisterAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabRegisterAccordionPayload']
      }
    ) => {
      state.tabs.payTab.registration = {
        ...current(state.tabs.payTab.registration),
        ...(payload ?? {}),
      }
    },
    updatePayTabTicketAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabTicketAccordionPayload']
      }
    ) => {
      state.tabs.payTab.ticket = {
        ...current(state.tabs.payTab.ticket),
        ...(payload ?? {}),
      }
    },
    updatePayTabFeesAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabFeesAccordionPayload']
      }
    ) => {
      state.tabs.payTab.fees = {
        ...current(state.tabs.payTab.fees),
        ...(payload ?? {}),
      }
    },
    updatePayTabGovernanceFeesAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabFeesAccordionPayload']
      }
    ) => {
      state.tabs.payTab.governance = {
        ...current(state.tabs.payTab.governance),
        ...(payload ?? {}),
      }
    },
    updatePayTabGrandTotalAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabGrandTotalAccordionPayload']
      }
    ) => {
      state.tabs.payTab.grandTotal = {
        ...current(state.tabs.payTab.grandTotal),
        ...(payload ?? {}),
      }
    },
    updatePayTabSummaryAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabSummaryAccordionPayload']
      }
    ) => {
      state.tabs.payTab.summary = {
        ...current(state.tabs.payTab.summary),
        ...(payload ?? {}),
      }
    },
    updatePayTabPaperworkAccordion: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IUpdatePayTabPaperworkAccordionPayload']
      }
    ) => {
      state.tabs.payTab.paperwork = {
        ...current(state.tabs.payTab.paperwork),
        ...(payload ?? {}),
      }
    },
    setPaperworkTabData: (state, { payload }: { payload: IPaperworkTab_[] }) => {
      state.tabs.paperworkTab = payload
    },
    setUserTeamMembers: (
      state,
      {
        payload,
      }: {
        payload: ITypes['IRegistrationInitialState']['data']['userTeamMembers']
      }
    ) => {
      state.data.userTeamMembers = payload
    },
    setRidersTeamMembers: (state, { payload }: { payload: IRiderTeamMemberInterface[] }) => {
      state.data.ridersTeamMembers = payload
    },
    setRidersTeamMembersInDb: (state, { payload }: { payload: IRiderTeamMemberInterface[] }) => {
      state.data.ridersTeamMembersInDb = payload
    },
    setFilteredPaperworkDocuments: (state, { payload }: { payload: IDocumentList }) => {
      state.data.filteredPaperworkDocuments = payload
    },
    setSignTab: (
      state,
      { payload }: { payload: ITypes['IRegistrationInitialState']['tabs']['signTab'] }
    ) => {
      state.tabs.signTab = payload
    },
    setRecipientsInDb: (
      state,
      { payload }: { payload: ITypes['IRegistrationInitialState']['data']['recipientsInDb'] }
    ) => {
      state.data.recipientsInDb = payload
    },
    setRecipientUser: (state, { payload }: { payload: IRecipientInterface }) => {
      state.tabs.payTab.summary.userData = payload
    },
    setSendMailLoading: (
      state,
      {
        payload,
      }: {
        payload: {
          sendMailIndex: ITypes['IRegistrationInitialState']['tabs']['signTab']['sendMailIndex']
          sendMailLoading: ITypes['IRegistrationInitialState']['tabs']['signTab']['sendMailLoading']
        }
      }
    ) => {
      if (typeof payload.sendMailIndex === 'number' || payload.sendMailIndex === null)
        state.tabs.signTab.sendMailIndex = payload.sendMailIndex

      if (typeof payload.sendMailLoading === 'boolean')
        state.tabs.signTab.sendMailLoading = payload.sendMailLoading
    },
    setPaying: (state, { payload }: PayloadAction<boolean>) => {
      state.paying = payload
    },
  },
})

// Exports

export const {
  setUserRegistrationErrors,
  setHorseRegistrationErrors,
  setTicketTabData,
  setTeamTabData,
  setFeesTabDataAc,
  setRegistrationAc,
  setRegisterTabData,
  setRegistrationsByDay,
  setRegistrationLoading,
  updatePayTabRegisterAccordion,
  updatePayTabTicketAccordion,
  setSignTab,
  setRecipientsInDb,
  setRecipientUser,
  updatePayTabFeesAccordion,
  updatePayTabSummaryAccordion,
  setPaperworkTabData,
  setRegistrationsTicketsAc,
  updatePayTabGovernanceFeesAccordion,
  setSendMailLoading,
  setRegistrationsFeesAc,
  updatePayTabGrandTotalAccordion,
  updateFeesTabRowInReduxAc,
  setUserTeamMembers,
  setRidersTeamMembers,
  setRidersTeamMembersInDb,
  setFilteredPaperworkDocuments,
  setPaying,
  updateTicketTabRowInReduxAc,
  updateRegistrationByDayInRedux,
  updatePayTabPaperworkAccordion,
  setRegistrationComments,
} = registrationSlice.actions

export const selectRegistrationErrors = (state: RootState) => state.registration.hasErrors
export const selectFeesTabDataR = (state: RootState) => state.registration.tabs.feesTab
export const selectRegistrationLoading = (state: RootState) => state.registration.loading
export const selectTicketTabData = (state: RootState) => state.registration.tabs.ticketTab
export const selectTeamTabData = (state: RootState) => state.registration.tabs.teamTab
export const selectRegistration = (state: RootState) => state.registration.data.registration
export const selectActiveRegistrationTab = (state: RootState) => state.registration.activeTab
export const selectSignTabDataR = (state: RootState) => state.registration.tabs.signTab
export const selectUserTeamMembersR = (state: RootState) => state.registration.data.userTeamMembers
export const selectRegisterTabData = (state: RootState) => state.registration.tabs.registerTab
export const selectRegistertrationPaying = (state: RootState) => state.registration.paying
export const selectRegistrationFeesR = (state: RootState) =>
  state.registration.data.registrationsFees
export const selectRegistrationByDay = (state: RootState) =>
  state.registration.data.registrationsByDay
export const selectPayTabSummaryAccordion = (state: RootState) =>
  state.registration.tabs.payTab.summary
export const selectRecipientsInDb = (state: RootState) => state.registration.data.recipientsInDb
export const selectPayTabRegistrationAccordion = (state: RootState) =>
  state.registration.tabs.payTab.registration
export const selectPayTabPaperworkAccordion = (state: RootState) =>
  state.registration.tabs.payTab.paperwork
export const selectPayTabTicketAccordion = (state: RootState) =>
  state.registration.tabs.payTab.ticket
export const selectRidersTeamMembersR = (state: RootState) =>
  state.registration.data.ridersTeamMembers
export const selectRidersTeamMembersInDb = (state: RootState) =>
  state.registration.data.ridersTeamMembersInDb
export const selectPayTabFeesAccordion = (state: RootState) => state.registration.tabs.payTab.fees
export const selectPayTabGovernanceFeesAccordion = (state: RootState) =>
  state.registration.tabs.payTab.governance
export const selectFilteredPaperworkDocuments = (state: RootState) =>
  state.registration.data.filteredPaperworkDocuments
export const selectPayTabGrandTotalAccordion = (state: RootState) =>
  state.registration.tabs.payTab.grandTotal
export const selectRegistrationTicketsR = (state: RootState) =>
  state.registration.data.registrationsTickets

export const selectRegistrationComments = (state: RootState) =>
  state.registration.data?.registrationsByDay[0]?.comments || []

export const selectPaperworkTabData = (state: RootState) => state.registration.tabs.paperworkTab
export const selectSendMailLoadingR = (state: RootState) => ({
  sendMailIndex: state.registration.tabs.signTab.sendMailIndex,
  sendMailLoading: state.registration.tabs.signTab.sendMailLoading,
})

export default registrationSlice.reducer
