import { IUserMode, USE_MODE_CONST } from './use-mode'
import { MODAL_CONSTS } from './modal-const'

const USER_MODES = USE_MODE_CONST

export const ROUTES_CONST = {
  ROOT: {
    TITLE: 'Root',
    URL: '/',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  LOGIN: {
    TITLE: 'Login',
    URL: '/login',
    SCOPE: [USER_MODES.GUEST] as IUserMode[],
  },

  GENERATE_TOKEN: {
    TITLE: 'Generate Token',
    URL: '/generate-token',
    SCOPE: [USER_MODES.GUEST] as IUserMode[],
  },

  SPONSOR_AND_VENDOR: {
    TITLE: 'Spons & Vend',
    URL: '/sponsor-and-vendor',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  VENDOR_PROFILE: {
    TITLE: 'Profile',
    URL: '/v-profile',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  VENDOR_TEAM: {
    TITLE: 'Teams',
    URL: '/teams',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  VENDOR_PRODUCTS: {
    TITLE: 'Products',
    URL: '/vendor-products',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  VENDOR_NEWS: {
    TITLE: 'News',
    URL: '/vendor-news',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  VENDOR_DEALS: {
    TITLE: 'Deals',
    URL: '/vendor-deals',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  BROWSE_EVENTS: {
    TITLE: 'Browse Events',
    URL: '/browse-events',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  LOGOUT: {
    TITLE: 'Logout',
    URL: '/logout',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  SIGNUP: {
    TITLE: 'Signup',
    URL: '/signup',
    SCOPE: [USER_MODES.GUEST] as IUserMode[],
  },

  PAPERWORK: {
    TITLE: 'Paperwork',
    URL: '/paperwork',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  BILLS: {
    TITLE: 'Bills',
    URL: '/competitor/allbills',
    QUERY_PARAMS: {
      REGISTRATION_ID: 'registrationId',
    },
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  RULEBOOK: {
    TITLE: 'Rulebook',
    URL: '#',
    MODAL: MODAL_CONSTS.AI_RULE_BOOK,
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  HELP: {
    TITLE: 'Help',
    URL: '/help',
    SCOPE: [
      USER_MODES.GUEST,
      USER_MODES.COMPETITOR,
      USER_MODES.VENDOR,
      USER_MODES.ORGANIZER,
    ] as IUserMode[],
  },

  FAQ: {
    TITLE: 'FAQ',
    URL: '/faq',
    DESCENDANT_OF: ['/help'],
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  POLICIES: {
    TITLE: 'Policies',
    URL: '/policies',
    DESCENDANT_OF: ['/help'],
    SCOPE: [
      USER_MODES.GUEST,
      USER_MODES.COMPETITOR,
      USER_MODES.VENDOR,
      USER_MODES.ORGANIZER,
    ] as IUserMode[],
  },

  SEARCH: {
    TITLE: 'Search',
    URL: '/search',
    SCOPE: [USER_MODES.GUEST] as IUserMode[],
  },

  HOME: {
    TITLE: 'Search',
    URL: '/home',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  VENDOR_HOME: {
    TITLE: 'Home',
    URL: '/v_home',
    SCOPE: [USER_MODES.VENDOR] as IUserMode[],
  },

  EVENT_DETAILS: {
    TITLE: 'Event Details',
    URL: '/events-details',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  REGISTER_EVENT: {
    TITLE: 'Register',
    URL: '/register',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR] as IUserMode[],
  },

  MANAGE_REGISTER_EVENT: {
    TITLE: 'Manage Register',
    URL: '/manage-register',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  UPCOMING_COMPETITIONS: {
    TITLE: 'Upcoming Competitions',
    URL: '/upcoming-competitions',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  NOT_FOUND: {
    TITLE: 'Not Found',
    URL: '/404',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  PROFILE: {
    TITLE: 'Profile',
    URL: '/profile',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  EXHIBITOR_REFUND: {
    TITLE: 'Exhibitor Refund',
    URL: '/exhibitor/refund',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  STAFF_PROFILE: {
    TITLE: 'Exhibitor Profile',
    URL: '/staff/profile',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  TICKET_DASHBOARD: {
    TITLE: 'Ticket dashboard',
    URL: '/manage/clinic/ticket-dashboard',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  NOTIFICATIONS: {
    TITLE: 'Notifications',
    URL: '/notifications',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  ORGANIZER_HOME: {
    TITLE: 'Home',
    BASE_PATH: '/organizer-home',
    URL: '/organizer-home/current',
    REACT_URL: '/organizer-home/:activeTab',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  ORGANIZER_EDIT: {
    TITLE: 'Edit Event',
    URL: '/organizer-edit',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  ORGANIZER_MANAGE: {
    TITLE: 'Manage',
    URL: '/organizer-manage',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  TEAM: {
    TITLE: 'Team',
    URL: '/profile/team',
    SUB_URL: 'team',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  HORSES: {
    TITLE: 'Horses',
    URL: '/profile/horses',
    SUB_URL: 'horses',
    SUBMENU: {
      SUB_TITLE: 'Edit horse',
      SUB_URL: '/edit-horse',
      SUB_URL2: '/add-horse',
      SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
    },
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  HORSE_PUBLIC_PROFILE: {
    TITLE: 'Horse Public Profile',
    URL: '/horse-public-profile',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  TICKETS: {
    TITLE: 'Tickets',
    URL: '/tickets-purchase/:id',
    PUSH_URL: '/tickets-purchase',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR] as IUserMode[],
  },

  PROFILE_SETTINGS: {
    TITLE: 'Settings',
    URL: '/settings',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER, USER_MODES.VENDOR] as IUserMode[],
    SUB_URL: 'account',
    SUBMENU: {
      HORSE: {
        URL: '/settings/horses',
        TITLE: 'Horses',
      },
      ACCOUNT: {
        URL: '/settings/account',
        TITLE: 'Account',
      },
    },
  },

  SPLIT_PAYMENT: {
    TITLE: 'Split payment page',
    URL: '/split_payment',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  MANAGE: {
    REVENUE: {
      TITLE: 'Finance Revenue',
      URL: '/manage/clinic/revenue',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
      SUB_URLS: {
        SPECTATOR: {
          TITLE: 'Spectator Revuenue',
          URL: '/manage/clinic/revenue/spectator/:eventId',
          SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
        },
        REGISTRATION: {
          TITLE: 'Spectator Revuenue',
          URL: '/manage/clinic/revenue/registration',
          SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
        },
      },
    },
    CLINIC_N_OTHER: {
      TITLE: 'Manage clinic and other',
      URL: '/manage/clinic',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
    },

    MANAGE_RIDER: {
      TITLE: 'Manage Rider',
      URL: '/manage/rider',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
    },

    EXHIBITORS: {
      TITLE: 'Exhibitors',
      URL: '/manage/clinic/exhibitors',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
    },
    STAFF: {
      TITLE: 'Staff',
      URL: '/manage/clinic/staff',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
    },
    HORSES: {
      TITLE: 'Horses',
      URL: '/manage/clinic/horses',
      SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
    },
  },

  CLINIC: {
    TITLE: 'Clinic',
    URL: '/clinic',
    SUB_URL: 'clinic',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  ACCOUNT_SETTINGS: {
    TITLE: 'Settings',
    URL: '/settings',
    SUB_URL: 'account',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  FINANCE: {
    TITLE: 'Finances',
    URL: '/finance',
    SCOPE: [USER_MODES.ORGANIZER] as IUserMode[],
  },

  USER_PROFILE: {
    TITLE: 'User Profile',
    URL: '/userprofile',
    SCOPE: [USER_MODES.GUEST, USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  ALL_EVENT: {
    TITLE: 'All Events',
    URL: '/allevent',
    SUB_URL: 'event',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  COMING_SOON: {
    TITLE: 'Messages',
    URL: '/comingsoon',
    SUB_URL: 'comingsoon',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER, USER_MODES.VENDOR] as IUserMode[],
  },

  SIGN_CONFIRMED: {
    TITLE: 'Signature confirmed',
    URL: '/confirmsign',
    SUB_URL: 'confirmsign',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  MY_EVENT: {
    TITLE: 'My events',
    URL: '/myevent',
    SUB_URL: 'myevent',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  EARN_COMMISSION: {
    TITLE: 'Earn',
    URL: '/earncommission',
    SUB_URL: 'earncommission',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  COMPETITOR_REGISTERED_EVENT: {
    TITLE: 'Registered Event',
    URL: '/registered/event',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  FOLLOWING_USER: {
    TITLE: 'Following',
    URL: '/following',
    SCOPE: [USER_MODES.COMPETITOR] as IUserMode[],
  },

  TEAM_PROFILE: {
    TITLE: 'teamProfile',
    URL: '/teamProfile',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER] as IUserMode[],
  },

  MESSAGES: {
    TITLE: 'Messages',
    URL: '/messages',
    SCOPE: [USER_MODES.COMPETITOR, USER_MODES.ORGANIZER, USER_MODES.VENDOR] as IUserMode[],
  },
}
