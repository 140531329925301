import { useParams } from 'react-router'

import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Interfaces
import { ITeamMember } from '../../../../models/users/user.interface'

// Constants
import { collection, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../../components/home/modals/UserProfileTeamTabAddTeamMember'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { getConvertedData } from '../../../../models/interface.helper'
import IRegistrationTeamsTypes from '../../../../models/registeration-teams/registration-teams.interface'
import { RegistrationTeamModel } from '../../../../models/registeration-teams/registration-teams.models'
import { UserModel } from '../../../../models/users/user.model'
import FirebaseApp from '../../../../services/firebaseApp'
import FirestoreService from '../../../../services/firestoreService'
import { selectBasicEventDetails, selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectUserTeamMembersR,
  setHorseRegistrationErrors,
  setTeamTabData,
  setUserRegistrationErrors,
} from '../../../../store/registration/registrationSlice'
import { UserHorseMappingModel } from '../../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'
import ITypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IconUser } from '../../../../components/icons/IconUser'
import { IconHorseImg } from '../../../../components/icons/IconHorseImg'
import { ProfileFields } from './components/ProfileFields'
import { NoProfileData } from './components/NoProfileData'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { selectIsLoggedIn } from '../../../../store/user/userSlice'
import {
  selectHorses,
  setHorses as setHorsesInStore,
  updateHorses,
} from '../../../../store/horses/horseSlice'
import { HorseModel } from '../../../../models/horse/horse.model'
import { Authentication } from '../../../../components/authentication'
import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { HorseCompetitonPaperworkModel } from '../../../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { IHorseCompetitorDocument } from '../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'

type Props = {
  step?: number
  title?: string
  horseData?: any
  description?: string
  handleForm?: () => void
  teamMembersLoading?: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}
const USER_HORSE_MAPPING_COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_HORSE_MAPPING

export const SETTING_ROUTE_CONST = CONST.ROUTES.ACCOUNT_SETTINGS

interface IUserExtendedWithStatus extends ITeamMember {
  status?: string
}
export interface IErrors {
  horses: { [key: string]: number }
  users: { [key: string]: number }
}
const USER_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS
const REGISTRATION_TEAMS = CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_TEAMS

const ClinicNOtherStatusTab = (props: Props) => {
  const myHorses = useAppSelector(selectHorses)

  const dispatch = useAppDispatch()
  const event = useAppSelector(selectedEvent)
  const { userId } = useParams<ITypes['IParams']>()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const selectedevent = useAppSelector(selectBasicEventDetails)
  const { mappings } = useHorses(userId)

  const setTeamDatafromDb = useState<IRegistrationTeamsTypes['IRegistrationTeamInterface'][]>([])[1]
  const [authorizedMeUsers, setAuthorizedMeUsers] = useState<IUserExtendedWithStatus[]>([])
  const [authmeHorses, setAuthmeHorses] = useState<IUserHorseMappingInterface[]>([])
  const [users, setUsers] = useState<UserModel[]>([])
  const [mainLoading, setMainLoading] = useState(true)
  const [horsesPaperwork, setHorsesPaperwork] = useState<IHorseCompetitorDocument[] | null>(null)
  const [totalErrors, setTotalErrors] = useState<{
    horses: { [key: string]: number }
    users: { [key: string]: number }
  }>({ horses: {}, users: {} })

  const getHorsesPaperwork = async () => {
    const horsesPaperworkSnap = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME
    )

    let horsesPaperwork_ = horsesPaperworkSnap?.docs.map((doc) => {
      return HorseCompetitonPaperworkModel.fromFirestoreDoc(doc).toObject()
    })
    setHorsesPaperwork([...horsesPaperwork_])
  }

  const handlegetTeamDatafromDb = async () => {
    let teams: IRegistrationTeamsTypes['IRegistrationTeamInterface'][] = []
    const teamSnaps = await FirestoreService.filterItems(REGISTRATION_TEAMS.NAME, [
      where(REGISTRATION_TEAMS.FIELDS.EVENT_ID.KEY, '==', selectedevent.id),
      where(REGISTRATION_TEAMS.FIELDS.USER_ID.KEY, '==', userId),
    ])

    teamSnaps.docs.forEach((currDoc) => {
      teams.push(getConvertedData(RegistrationTeamModel.fromFirestoreDoc(currDoc).toObject()))
    })

    if (!teams) throw new Error('teams not exists')

    setTeamDatafromDb(teams)
    dispatch(setTeamTabData(teams))
  }

  useEffect(() => {
    handlegetTeamDatafromDb().then()
    getHorsesPaperwork().then()
  }, [selectedevent.id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const firebaseQueries: any[] = (function (): any {
    let queries: any = []
    queries.push(
      where(USER_TABLE.FIELDS.USER_TYPE.KEY, '==', USER_TABLE.FIELDS.USER_TYPE.VALUES.COMPETITOR)
    )
    return queries
  })()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const [value] = useCollection(
    query(collection(FirebaseApp.firestore, USER_TABLE.NAME), ...firebaseQueries),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  useEffect(() => {
    const getAllCompetitors = async () => {
      try {
        const fetched_users: IUserExtendedWithStatus[] = []
        const ownerIds: string[] = []
        const authHorseData: IUserHorseMappingInterface[] = []
        if (value?.size) {
          value?.docs.map((doc: any) => {
            let user = UserModel.fromFirestoreDoc(doc).toObject()
            if (user.userTeamMembers && user.userTeamMembers.length) {
              user.userTeamMembers.map((currentMember: ITeamMember) => {
                if (
                  user.id === userId
                  // && currentMember.memberAuthorized === '1'
                ) {
                  fetched_users.push({
                    ...currentMember,
                    // status: currentMember.memberAuthorized === '1' ? 'authorized' : 'unauthorized',
                  })
                  ownerIds.push(user.id)
                }
                return true
              })
            }
            return true
          })
        }

        setAuthorizedMeUsers(fetched_users)

        if (ownerIds.length) {
          const horseMappingSnap = await FirestoreService.filterItems(
            USER_HORSE_MAPPING_COLLECTION.NAME,
            [
              where(USER_HORSE_MAPPING_COLLECTION.FIELDS.HORSE_OWNER_ID.KEY, 'in', ownerIds),
              where(USER_HORSE_MAPPING_COLLECTION.FIELDS.IS_MY_HORSE.KEY, '==', true),
            ]
          )
          if (!horseMappingSnap.size) return 0

          horseMappingSnap.forEach((currSnap) =>
            authHorseData.push(
              getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currSnap).toObject())
            )
          )
        }
        if (authHorseData[0]) setAuthmeHorses(authHorseData)
      } catch (err) {
        console.log('err', err)
      }
    }
    getAllCompetitors().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    ;(async () => {
      if (!teamMembersWithUser[0]) return null
      const ids = teamMembersWithUser.map((user) => user.memberId) || []
      if (!ids[0]) return null

      const snapshotPromises: Promise<any>[] = []
      const horsesFromDb = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES.NAME,
        [where(CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES.FIELDS.OWNER_ID.KEY, 'in', ids)]
      )

      const horsesData: any = []

      if (horsesFromDb.size) {
        horsesFromDb.forEach((currSnap) =>
          horsesData.push(
            getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currSnap).toObject())
          )
        )
      }

      const newHorses: any = []

      const horsesIds: string[] = horsesData.map((h: any) => h.id)

      if (mappings.data && Array.isArray(mappings.data)) {
        for (const itm of mappings.data) {
          const isInArr = newHorses.find((h: any) => h.id === itm.horseId)
          if (!isInArr && itm.horseId) {
            horsesIds.push(itm.horseId)
          }
        }
      }

      let horsesnaps = await FirestoreService.getItemsUsingIds(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
        horsesIds
      )

      horsesnaps.forEach((currDoc) => {
        newHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })

      dispatch(setHorsesInStore(newHorses))

      for (let id of ids)
        if (id) {
          snapshotPromises.push(
            FirestoreService.getItem(CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME, id)
          )
        }
      try {
        const userSnapshots = await Promise.all(snapshotPromises)
        const _users: UserModel[] = []
        for (let userSnapshot of userSnapshots) {
          if (userSnapshot.exists()) {
            const user = UserModel.fromFirestoreDoc(userSnapshot)
            _users.push(user)
          }
        }
        setUsers(_users)
      } catch (e) {
        console.log('=>(ClinicNOtherStatusTab.tsx:205) e', e)
      } finally {
        setMainLoading(false)
      }
    })()
  }, [teamMembersWithUser])

  useEffect(() => {
    const sumHorses = Object.values(totalErrors.horses).reduce((acc, val) => acc + val, 0)
    const sumUsers = Object.values(totalErrors.users).reduce((acc, val) => acc + val, 0)

    if (sumHorses > 0) {
      dispatch(setHorseRegistrationErrors(true))
    } else {
      dispatch(setHorseRegistrationErrors(false))
    }

    if (sumUsers > 0) {
      dispatch(setUserRegistrationErrors(true))
    } else {
      dispatch(setUserRegistrationErrors(false))
    }
  }, [totalErrors])

  const teamMemberComp = React.useMemo(() => {
    if (mainLoading) {
      return (
        <ViewsLoader
          className="flex items-center md:w-80 w-full justify-center my-3"
          size="md"
          color="red"
        />
      )
    }
    // germandavidgz+121043@gmail.com

    if ((!Array.isArray(users) || !users[0]) && isLoggedIn) {
      return (
        <NoProfileData
          title={'You have not created a profile'}
          createHandler={() => {
            props.handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
          }}
          btnCreateTitle={'Create profile to register >'}
        />
      )
    }

    if (!isLoggedIn) {
      return (
        <div className={'flex items-center justify-center pt-10'}>
          <Authentication
            handleModal={props.handleModal}
            registerHandler={() => {
              props.handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER, { shouldBeLoggedIn: true })
            }}
          />
        </div>
      )
    }

    return users.map((item, index) => {
      return (
        <ProfileFields
          eventRequiredFields={event?.Event?.requiredFields || []}
          key={`${item.id} + ${index}`}
          saveCallBack={(value, key, id) => {
            const _users: any = [...users]
            const userIndex = _users.findIndex((u: any) => `${u.id}` === `${id}`)
            if (userIndex < 0) {
              return console.log('Can`t find user index')
            }

            _users[userIndex] = {
              ..._users[userIndex],
              [key]: value,
            }
            setUsers(_users)
          }}
          user={item}
          editHandler={(data) => {
            props.handleModal(true, MODAL_CONSTS.UPDATE_USER_NUMBER, {
              data,
              userData: item,
              cb: (
                dataValue: {
                  documentNumber: string
                  endDate: string
                  membershipStatus: string
                },
                id: string,
                key: string
              ) => {
                const _users: any = [...users]

                const userIndex = _users.findIndex((u: any) => `${u.id}` === `${id}`)

                if (userIndex < 0) {
                  return console.log('Can`t find user index')
                }

                _users[userIndex] = {
                  ..._users[userIndex],
                  [key]: {
                    ..._users[userIndex][key],
                    ...dataValue,
                  },
                }
                setUsers(_users)
              },
            })
          }}
          headerImgSrc={item?.userProfilePicture || ''}
          headerTitle={
            item.userFirstName || item.userLastName
              ? `${item.userFirstName} ${item.userLastName}`
              : item.userName || ''
          }
          horsesPaperwork={horsesPaperwork}
          setTotalErrors={setTotalErrors}
          totalErrors={totalErrors}
        />
      )
    })
  }, [users, mainLoading, event?.Event?.requiredFields, horsesPaperwork])

  const renderHorses = React.useMemo(() => {
    if (!myHorses || !myHorses[0]) {
      return (
        <NoProfileData
          title={'You have not added any horses'}
          createHandler={() => {
            if (users[0]?.id) {
              props.handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
                inputData: {},
                userPicture: '',
                userId: users[0].id,
                createCb: () => {
                  props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
                },
              })
            }
          }}
          btnCreateTitle={'Add your first horse now >'}
        />
      )
    }

    return myHorses.map((item: any, index: number) => (
      <ProfileFields
        eventRequiredFields={event?.Event?.requiredHorseFields || []}
        key={`${item.horseName} + ${index}`}
        horse={item}
        headerImgSrc={item?.horseProfilePicture || ''}
        headerTitle={item?.horseName || 'Unknown'}
        saveCallBack={(value, key, id) => {
          const _horses: any = [...myHorses]
          const horseIndex = _horses.findIndex((h: any) => `${h.id}` === `${id}`)
          if (horseIndex < 0) {
            return console.log('Can`t find horse index')
          }
          _horses[horseIndex] = {
            ..._horses[horseIndex],
            [key]: value,
          }

          dispatch(updateHorses(_horses))
        }}
        isHorse
        editHandler={async (profileFiled, horse) => {
          if (
            profileFiled.value === 'horseMeasurementCard' ||
            profileFiled.value === 'horseCoggins' ||
            profileFiled.value === 'horseHealthCertificate' ||
            profileFiled.value === 'horseRhinopneumonitis' ||
            profileFiled.value === 'horseInfluenza'
          ) {
            const horsePaperwork = horsesPaperwork?.find(
              (horsePaperwork_) => horsePaperwork_.horseId === horse?.horseId
            )
            props.handleModal(true, MODAL_CONSTS.UPDATE_TEAM_DETAILS, {
              profileFiled,
              horsePaperwork: horsePaperwork,
              selectedHorse: horse,
              onSave: async () => await getHorsesPaperwork(),
            })
          } else {
            props.handleModal(true, MODAL_CONSTS.UPDATE_USER_NUMBER, {
              data: profileFiled,
              isHorse: true,
              horseData: item,
              cb: (
                dataValue: {
                  documentNumber: string
                  endDate: string
                  membershipStatus: string
                },
                id: string,
                key: string
              ) => {
                const _horses: any = [...myHorses]

                const horseIndex = _horses.findIndex((u: any) => `${u.id}` === `${id}`)
                const cutKey = key.replace('Number', '')

                if (horseIndex < 0) {
                  return console.log('Can`t find horse index')
                }

                _horses[horseIndex] = {
                  ..._horses[horseIndex],
                  [key]: dataValue.documentNumber,
                  [cutKey + 'Expiration']: dataValue.endDate,
                }

                dispatch(updateHorses(_horses))
              },
            })
          }
        }}
        horsesPaperwork={horsesPaperwork}
        setTotalErrors={setTotalErrors}
        totalErrors={totalErrors}
      />
    ))
  }, [myHorses, event?.Event?.requiredHorseFields, mainLoading, horsesPaperwork])

  return (
    <>
      <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
        <div className="flex flex-col lg:flex-row flex-1 gap-5">
          <div
            className={`flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-5 pb-[300px]`}
          >
            {/* Team members section */}
            <div className={'flex w-full justify-between'}>
              <div>
                <div className="flex items-center">
                  <h3 className="text-[#122B46] text-[22px] font-bold mr-1">People</h3>
                  <Tooltip title={''} placement="top" arrow>
                    <button
                      type="button"
                      className=""
                      onClick={() => props.handleModal(true, MODAL_CONSTS.REGISTRATION_PEOPLE)}
                    >
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <p className={'text-[#122B4680] text-[14px]'}>
                  Add all team members included in registration
                </p>
              </div>
              <div className={'flex gap-5'}>
                {isLoggedIn && (
                  <div className={'flex flex-col text-right'}>
                    <span className={'text-[#122B4680] text-[12px]'}>Team</span>
                    <button
                      disabled={mainLoading}
                      onClick={() => {
                        props.handleModal(true, MODAL_CONSTS.REGISTER_TEAM, {
                          modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
                          authorizeUser: authorizedMeUsers,
                          authorizeuserHorses: authmeHorses,
                          createCb: (horse: IHorseData) => {
                            dispatch(updateHorses([...myHorses, horse]))
                            props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
                          },
                        })
                      }}
                      className={'text-[14px] text-[#122B46] underline hover:no-underline'}
                    >
                      Add person
                    </button>
                  </div>
                )}

                <div
                  className={
                    'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'
                  }
                >
                  <IconUser />
                </div>
              </div>
            </div>

            {teamMemberComp}
          </div>

          {/* Horse section */}
          <div
            className={`flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-5 pb-[300px] ${!isLoggedIn ? 'opacity-30' : ''}`}
          >
            <div className={'flex w-full justify-between'}>
              <div>
                <div className="flex items-center">
                  <h3 className="text-[#122B46] text-[22px] font-bold mr-1">Horses</h3>
                  <Tooltip title={''} placement="top" arrow>
                    <button
                      type="button"
                      className=""
                      onClick={() => props.handleModal(true, MODAL_CONSTS.REGISTRATION_HORSES)}
                    >
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <p className={'text-[#122B4680] text-[14px]'}>
                  The below fields must be filled to register
                </p>
              </div>
              <div className={'flex gap-5'}>
                {isLoggedIn && (
                  <div className={'flex flex-col text-right'}>
                    <span className={'text-[#122B4680] text-[12px]'}>Team</span>
                    <button
                      onClick={() => {
                        props.handleModal(true, MODAL_CONSTS.HORSE)
                      }}
                      className={'text-[14px] text-[#122B46] underline hover:no-underline'}
                    >
                      Add horse
                    </button>
                  </div>
                )}

                <div
                  className={
                    'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'
                  }
                >
                  <IconHorseImg />
                </div>
              </div>
            </div>

            {renderHorses}
          </div>
        </div>
      </CompetitorEventRegisterWrapper>
    </>
  )
}

export default ClinicNOtherStatusTab
