import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { IconAttach } from '../../../../../components/icons/IconAttach'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import FirebaseStorageService from '../../../../../services/storageService'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import TimeLib from '../../../../../lib/Time'
import helpers from '../../../../../commonHelpers/helpers'
import { IconClose } from '../../../../../components/icons/IconClose'

interface IProps {
  title: string
  questionId: string
  defaultValue?: string
  handleChange: (value: string) => void
  addFile: (url: string) => void
  files: string[]
  removeFile: (val: string, index: number) => void
}

export const CommentsQuestion: React.FC<IProps> = ({
  title,
  handleChange,
  defaultValue,
  questionId,
  files,
  addFile,
  removeFile,
}) => {
  const toastFunctions = useToasterHelper()
  const [imgLoading, setImgLoading] = useState<boolean>(false)

  const addFileInner = async (event: any) => {
    const inputFiles = event.target.files
    const file = inputFiles[0]
    const PATH = CONST.DATA.STORAGE.USERS.ANSWERS_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${questionId}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )

      addFile(downloadUrl as string)
      toastFunctions.success({ message: MESSAGES_CONST.FILE_UPLOADED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }
  return (
    <div className={'w-full'}>
      <h4 className={'text-[#122B46] font-bold mb-5'}>{title}</h4>
      <div className={'mt-2 rounded-lg border-[1px] border-solid border-[#D3DAEE] w-full'}>
        <TextareaAutosize
          defaultValue={defaultValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={'Enter Answer....'}
          className={'border-0 rounded-lg w-full outline-none !focus:outline-none'}
          style={{ boxShadow: 'none' }}
        />
        <div className={'border-t border-solid border-t-[#D3DAEE] p-3 flex gap-5'}>
          {files.map((f, i) => {
            return (
              <div className={'relative'}>
                <img className={'w-[45px] h-[45px] rounded-lg'} src={f} alt={'image-' + i} />
                <button
                  className={
                    'absolute top-[-10px] cursor-pointer right-[-10px] border-solid border-[#D3DAEE] border-[1px] bg-white rounded-full w-[20px] h-[20px] flex items-center justify-center p-1'
                  }
                  onClick={() => removeFile(f, i)}
                >
                  <IconClose />
                </button>
              </div>
            )
          })}
          <button
            className={
              'inline-flex relative gap-2 items-center cursor-pointer  hover:text-[#F7074F] text-[#122B4680] text-[12px] group'
            }
          >
            <IconAttach />
            <span>Attach file</span>
            <input
              type="file"
              name={'fileImage'}
              accept=".jpeg, .png, .jpg"
              onChange={(e: any) => addFileInner(e)}
              className="absolute cursor-pointer h-[100%] w-[100%] opacity-0 left-0 right-0 top-0 bottom-0 cursor-pointer z-10"
            />
          </button>
        </div>
      </div>
    </div>
  )
}
