import React, { FC } from 'react'
import clsx from 'clsx'
import { Add, HighlightOff } from '@mui/icons-material'
import { Control, Controller, UseFormWatch } from 'react-hook-form'

import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'

import { IOnRemoveImageButtonClickProps } from '../HorsePaperwork'

import { getReactPickerDate } from '../../../models/interface.helper'
import { IHorseData } from '../../../models/horse/horse.interface'
import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { IProfileReqField } from '../../competitor/clinicAndOther/Tabs/components/ProfileFields'

interface CogginsProps {
  getImage: (image: string) => { url: string; type: string }
  handleManageToastConfirm: (
    index: number,
    type: IOnRemoveImageButtonClickProps['type'],
    rowIndex?: number
  ) => void
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
  selectedHorse: IHorseData | null
  imageSetter: (key: any, imageUrls: string[]) => void
  watch: UseFormWatch<IHorseCompetitorDocument>
  control: Control<IHorseCompetitorDocument>
  saved: boolean
  profileFiled?: IProfileReqField
}

export const Coggins: FC<CogginsProps> = ({
  watch,
  control,
  saved,
  getImage,
  imageSetter,
  handleModal,
  handleManageToastConfirm,
  selectedHorse,
  profileFiled,
}) => {
  const onUploadCogginsCertificateClick = () => {
    if (!selectedHorse?.id || saved) return

    handleModal?.(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseCogginsInspectionCertificate',
      data: watch(),
      setter: imageSetter,
      profileFiled: profileFiled,
    })
  }

  return (
    <>
      <div className="flex items-center mt-2 w-full gap-4">
        <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="measurementCard" />
          Upload Coggins Certificate
        </label>
        <div className="wrapper flex flex-col gap-4 flex-grow py-2">
          <div className="flex flex-wrap rounded-md relative gap-2">
            {(watch('horseCogginsInspectionCertificate') ?? []).map(
              (item: string, index: number) => {
                return (
                  <div
                    key={`horseCogginsInspectionCertificate${item}${index}`}
                    className={clsx('image-preview rounded-md w-24 h-24 z-[0] relative', {
                      border: getImage(item).type === 'image',
                    })}
                  >
                    <img
                      src={getImage(item).url}
                      className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                      alt="horseCogginsInspectionCertificate"
                      onClick={() => window.open(item, '_blank')}
                    />
                    {!saved ? (
                      <button
                        onClick={() => {
                          handleManageToastConfirm(index, 'horseCogginsInspectionCertificate')
                        }}
                        type="button"
                        className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                      >
                        <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                      </button>
                    ) : null}
                  </div>
                )
              }
            )}
          </div>

          {!saved ? (
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={onUploadCogginsCertificateClick}
            >
              <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex items-center mt-2 w-full gap-4">
        <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Certificate.png" className="mr-4 w-6" alt="measurementCard" />
          Coggins inspection date
        </label>
        <div className="wrapper flex flex-wrap w-4/5">
          <Controller
            name={`horseCogginsInspectionDate`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <CustomDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={getReactPickerDate(value)}
                  peekNextMonth
                  dropdownMode="select"
                  wrapperClassName="w-auto"
                  disabled={saved}
                  showMonthDropdown
                  popperPlacement="bottom"
                  showYearDropdown
                  placeholderText="Measurement date"
                  className={`rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 py-2.5 pl-4 !text-sm mr-2  w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
              )
            }}
          />
        </div>
      </div>
    </>
  )
}
